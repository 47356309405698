import { Box, IconButton, Link, Typography } from '@mui/material';
import TopNavigationButton from 'components/top/TopNavigationButton';
import React from 'react';
import ShopHeader from 'components/ShopHeader';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { setSituation, SituationType } from 'store/requestSlice';
import { setIsShowIntroduction } from 'store/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import IntroductionModal from 'components/modal/IntroductionModal';
import { ReactComponent as BGImage3 } from 'assets/images/common/bg.svg';
import { ReactComponent as InfoIcon } from 'assets/images/top/info-icon.svg';
import TopWaitingTimeForEyeTest from 'components/top/TopWaitingTimeForEyeTest';
import TopWaitingTimeForFinishing from 'components/top/TopWaitingTimeForFinishing';
import TopWaitingNumberForAdjustment from 'components/top/TopWaitingNumberForAdjustment';
import { firestore } from '../../utils/firebase';
import { query, where, onSnapshot, collection } from 'firebase/firestore';
import { setStoreCode, setStoreData } from 'store/storeSlice';
import { setErrorInfo } from 'store/errorSlice';
import { httpsCallable } from 'firebase/functions';
import { functions } from 'utils/firebase';
import LineNotificationModal from 'components/modal/LineNotificationModal';
import RequestCompleteModal from 'components/modal/RequestCompleteModal';
import ErrorModal from 'components/modal/ErrorModal';
import CompleteModal from 'components/modal/CompleteModal';
import { dateFormat, addMinutes } from '../../utils/datetime';
import { ReactComponent as Loading } from 'assets/images/common/LINE_spinner_light.svg';
import Backdrop from '@mui/material/Backdrop/Backdrop';
import { pushExtStatus, setCallCode } from 'store/receiptSlice';
import { pushDataLayer } from '../../utils/gtm';

export enum TopMode {
  'top',
  'buy',
  'adjustment',
  'completed',
}

type TopProps = {
  mode?: TopMode;
};

const Top = (props: TopProps) => {
  const [isLoading, setLoading] = useState(true);
  const userInfo = useSelector((state: RootState) => state.user);
  const storeInfo = useSelector((state: RootState) => state.store);
  const userReceiptInfo = useSelector(
    (state: RootState) => state.receipt.user_receipt,
  );
  const receiptInfo = useSelector(
    (state: RootState) => state.receipt.max_priority,
  );
  const receiptList = useSelector(
    (state: RootState) => state.receipt.receipt_status,
  );
  const extStatusInfo = useSelector(
    (state: RootState) => state.receipt.ext_status,
  );
  const initMode: TopMode =
    props.mode === undefined
      ? TopMode.top
      : props.mode === TopMode.completed
      ? TopMode.top
      : props.mode;
  const [mode, setMode] = useState(initMode);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [errorModalText, setErrorModalText] = useState('');
  const [completedModalOpen, setCompletedModalOpen] = useState(false);
  const [loadingOpen, setLoadingOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function navigateToStandby(situation: SituationType) {
    const now = dateFormat(new Date(), 'hh:mm');
    if (now < storeInfo.checkin_start_at || now > storeInfo.checkin_end_at) {
      dispatch(
        setErrorInfo({
          body1: 'サービス時間外です。店舗の営業時間内のみご利用いただけます。',
        }),
      );
      navigate('/error');
    } else {
      try {
        console.log(
          dateFormat(new Date(), 'YYYY-MM-DDThh:mm:ss+0900'),
          'lambda-post_receptions...',
        );
        setLoadingOpen(true);
        dispatch(setSituation(situation));
        const postReceptions = httpsCallable(
          functions,
          'lambda-post_receptions',
        );
        postReceptions({
          channel_id: process.env.REACT_APP_LINE_CHANNEL_ID,
          line_id: userInfo.uid,
          store_code: storeInfo.store_code,
          repair_kbn: '0',
          line_purpose: 'F',
        }).then((res: any) => {
          console.log(dateFormat(new Date(), 'YYYY-MM-DDThh:mm:ss+0900'), res);
          if (res.data.status_code === 200 && res.data.body.return_code === 0) {
            pushDataLayer({
              event: 'liff_check_in',
            });
            const at = dateFormat(
              addMinutes(new Date(), -9 * 60),
              'YYYY-MM-DDThh:mm:ss.000Z',
            );
            dispatch(setCallCode(res.data.body.data.call_code));
            for (const recp of res.data.body.data.receipt_no) {
              dispatch(
                pushExtStatus({
                  receipt_no: recp,
                  line_user_id: userInfo.uid || '',
                  ext_state: '500_adj1_prep',
                  be_updated_at: at,
                }),
              );
            }
            setModalOpen(true);
            setLoadingOpen(false);
          } else {
            setErrorModalText(
              res.data.body.return_message ||
                '現在スマートチェックインを利用できません。スタッフにお声かけください。',
            );
            setErrorModalOpen(true);
            setLoadingOpen(false);
          }
        });
      } catch (e) {
        setErrorModalText(
          '現在スマートチェックインを利用できません。スタッフにお声かけください。',
        );
        setErrorModalOpen(true);
        setLoadingOpen(false);
      }
    }
  }

  const nextHandle = () => {
    navigate('/standby/wait-datasync');
  };

  function navigateToInterview(situation: SituationType) {
    const now = dateFormat(new Date(), 'hh:mm');
    if (now < storeInfo.checkin_start_at || now > storeInfo.checkin_end_at) {
      dispatch(
        setErrorInfo({
          body1: 'サービス時間外です。店舗の営業時間内のみご利用いただけます。',
        }),
      );
      navigate('/error');
    } else {
      dispatch(setSituation(situation));
      navigate('/interview');
    }
  }

  function changeTopMode(mode: TopMode.buy | TopMode.adjustment) {
    const now = dateFormat(new Date(), 'hh:mm');
    if (now < storeInfo.checkin_start_at || now > storeInfo.checkin_end_at) {
      dispatch(
        setErrorInfo({
          body1: 'サービス時間外です。店舗の営業時間内のみご利用いただけます。',
        }),
      );
      navigate('/error');
    } else {
      setMode(mode);
    }
  }

  const [modalOpen, setModalOpen] = useState(false);
  const [lineNotificationModalOpen, setLineNotificationModalOpen] =
    useState(false);

  // このアプリでできることの表示切り替え
  const [open, setOpen] = useState(!userInfo.is_show_introduction);

  const [modalIndex, setModalIndex] = useState(0);
  const handleClose = () => {
    setOpen(false);
    dispatch(setIsShowIntroduction());
  };
  const changeIndexHandle = (index: number) => {
    setModalIndex(index);
  };
  const toActiveStatusPage = () => {
    const ext_status =
      receiptInfo?.receipt_no &&
      extStatusInfo[receiptInfo.receipt_no]?.ext_state;

    // 測定待ち
    if (
      receiptInfo?.ticket_status === '100' &&
      ext_status !== '100_ext' &&
      ext_status !== 'cancel'
    ) {
      navigate('/standby/wait-measurement');
    }
    // 受付（問診）待ち
    else if (
      receiptInfo?.ticket_status === '200' &&
      ext_status !== '200_ext' &&
      ext_status !== 'cancel'
    ) {
      navigate('/standby/wait-interview');
    }
    // 調整待ち
    else if (
      receiptInfo?.ticket_status === '500' &&
      ext_status !== '500_adj1_ext' &&
      ext_status !== 'cancel' &&
      receiptInfo?.ticket_adjust_kbn === '1'
    ) {
      navigate('/standby/wait-adjustment');
    }
    // 測定呼出
    else if (
      receiptInfo?.ticket_status === '110' ||
      receiptInfo?.ticket_status === '120' ||
      receiptInfo?.ticket_status === '190' ||
      receiptInfo?.ticket_status === '191' ||
      receiptInfo?.ticket_status === '192' ||
      (receiptInfo?.ticket_status === '100' && ext_status === '100_ext')
    ) {
      navigate('/standby/call-measurement');
    }
    // 受付（問診）呼出
    else if (
      receiptInfo?.ticket_status === '210' ||
      receiptInfo?.ticket_status === '220' ||
      receiptInfo?.ticket_status === '290' ||
      receiptInfo?.ticket_status === '291' ||
      receiptInfo?.ticket_status === '292' ||
      (receiptInfo?.ticket_status === '200' && ext_status === '200_ext')
    ) {
      navigate('/standby/call-interview');
    }
    // 調整呼出
    else if (
      (receiptInfo?.ticket_status === '510' ||
        receiptInfo?.ticket_status === '520' ||
        receiptInfo?.ticket_status === '590' ||
        receiptInfo?.ticket_status === '591' ||
        receiptInfo?.ticket_status === '592' ||
        (receiptInfo?.ticket_status === '500' &&
          ext_status === '500_adj1_ext')) &&
      receiptInfo?.ticket_adjust_kbn === '1'
    ) {
      navigate('/standby/call-adjustment');
    }
    // 受取待ち
    else if (
      receiptInfo?.ticket_status === '500' &&
      ext_status !== '500_adj0_ext' &&
      ext_status !== 'cancel' &&
      receiptInfo?.ticket_adjust_kbn === '0'
    ) {
      navigate('/standby/wait-acceptance');
    }
    // 受取呼出
    else if (
      (receiptInfo?.ticket_status === '510' ||
        receiptInfo?.ticket_status === '520' ||
        receiptInfo?.ticket_status === '590' ||
        receiptInfo?.ticket_status === '591' ||
        receiptInfo?.ticket_status === '592' ||
        (receiptInfo?.ticket_status === '500' &&
          ext_status === '500_adj0_ext')) &&
      receiptInfo?.ticket_adjust_kbn === '0'
    ) {
      navigate('/standby/call-acceptance');
    }
  };

  useEffect(() => {
    if (storeInfo.store_code) {
      dispatch(setStoreData(undefined));
      const q = query(
        collection(firestore, '/store-state'),
        where('store_code', '==', storeInfo.store_code),
      );
      const unsubscribe = onSnapshot(q, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          const storeDate = change.doc.data();
          if (change.type === 'added' || change.type === 'modified') {
            dispatch(
              setStoreData({
                store_a_waiting_people: storeDate?.store_a_waiting_people,
                store_b_waiting_people: storeDate?.store_b_waiting_people,
                store_c_waiting_people: storeDate?.store_c_waiting_people,
                store_f_waiting_people: storeDate?.store_f_waiting_people,
                store_waiting_time: storeDate?.store_waiting_time,
                store_take_lens_time: storeDate?.store_take_lens_time,
                store_active_lines: storeDate?.store_active_lines,
                store_reception_status: storeDate?.store_reception_status,
                store_process_status: storeDate?.store_process_status,
                store_ticket_count: storeDate?.store_ticket_count,
                be_updated_at: storeDate?.be_updated_at,
              }),
            );
          } else if (change.type === 'removed') {
            dispatch(setStoreData(undefined));
          }
        });
      });
      return () => unsubscribe();
    }
  }, [storeInfo.store_code]);

  useEffect(() => {
    console.log('props.mode : ' + props.mode);
    const f = async () => {
      const now = dateFormat(new Date(), 'hh:mm');
      if (
        (!storeInfo.store_code &&
          (now < (process.env.REACT_APP_STORE_OPEN_TIME || '10:00') ||
            now > (process.env.REACT_APP_STORE_CLOSE_TIME || '22:00'))) ||
        (storeInfo.store_code &&
          (now < storeInfo.checkin_start_at || now > storeInfo.checkin_end_at))
      ) {
        // サービス時間外
        dispatch(
          setErrorInfo({
            body1:
              'サービス時間外です。店舗の営業時間内のみご利用いただけます。',
          }),
        );
        navigate('/error');
      } else if (props.mode && props.mode === TopMode.completed) {
        // 加工完了
        if (userInfo?.receipt_no && userReceiptInfo[userInfo.receipt_no]) {
          if (
            storeInfo.store_code !==
            userReceiptInfo[userInfo.receipt_no].store_code
          ) {
            dispatch(
              setStoreCode(userReceiptInfo[userInfo.receipt_no].store_code),
            );
          }
          if (
            now < storeInfo.checkin_start_at ||
            now > storeInfo.checkin_end_at
          ) {
            // サービス時間外
            dispatch(
              setErrorInfo({
                body1:
                  'サービス時間外です。店舗の営業時間内のみご利用いただけます。',
              }),
            );
            navigate('/error');
          }
          setCompletedModalOpen(true);
        } else {
          if (!storeInfo.store_code) {
            dispatch(
              setErrorInfo({
                body1:
                  '受付データが存在しませんので、こちらからスマートチェックインを利用できません。店舗でQRを読み込んで受付をはじめてください。',
              }),
            );
            navigate('/error');
          }
        }
      } else if (receiptInfo) {
        // アクティブな受付票あり
        if (storeInfo.store_code !== receiptInfo.store_code) {
          dispatch(setStoreCode(receiptInfo.store_code));
        }
        if (
          now < storeInfo.checkin_start_at ||
          now > storeInfo.checkin_end_at
        ) {
          // サービス時間外
          dispatch(
            setErrorInfo({
              body1:
                'サービス時間外です。店舗の営業時間内のみご利用いただけます。',
            }),
          );
          navigate('/error');
        }
        if (props.mode === undefined) {
          // TOP以外から流入
          toActiveStatusPage();
        } else {
          // TOPから流入
          setErrorModalText(
            '既に受付済のデータがあるため、スマートチェックインを利用できません。店頭のiPadより受付ください。',
          );
          setErrorModalOpen(true);
        }
      } else {
        const ext_active = Object.keys(extStatusInfo)
          .map(function (key) {
            return extStatusInfo[key];
          })
          .filter((v) => {
            const index = receiptList.findIndex(
              (item) => item.receipt_no === v.receipt_no,
            );
            return (
              v.be_updated_at &&
              dateFormat(new Date(v.be_updated_at), 'YYYY/MM/DD') ===
                dateFormat(new Date(), 'YYYY/MM/DD') &&
              (v.ext_state === '100_prep' ||
                v.ext_state === '200_prep' ||
                v.ext_state === '500_adj1_prep') &&
              index < 0
            );
          });
        if (ext_active && ext_active.length > 0) {
          navigate('/standby/wait-datasync');
        } else if (!storeInfo.store_code) {
          if (userInfo?.cancel_shop_code) {
            dispatch(setStoreCode(userInfo.cancel_shop_code));
            if (
              now < storeInfo.checkin_start_at ||
              now > storeInfo.checkin_end_at
            ) {
              // サービス時間外
              dispatch(
                setErrorInfo({
                  body1:
                    'サービス時間外です。店舗の営業時間内のみご利用いただけます。',
                }),
              );
              navigate('/error');
            }
          } else {
            dispatch(
              setErrorInfo({
                body1:
                  '受付データが存在しませんので、こちらからスマートチェックインを利用できません。店舗でQRを読み込んで受付をはじめてください。',
              }),
            );
            navigate('/error');
          }
        }
      }
      setLoading(false);
    };
    if (
      !loadingOpen &&
      !modalOpen &&
      !lineNotificationModalOpen &&
      !errorModalOpen
    ) {
      f();
    }
  }, [receiptInfo, extStatusInfo]);

  return !isLoading ? (
    <Box
      sx={{
        minHeight: '100vh',
        bgcolor:
          mode === TopMode.top
            ? '#F3E8E0'
            : mode === TopMode.buy
            ? '#C7E4D6'
            : '#CDE2F2',
        transition: '0.25s',
        pb: 16,
      }}
    >
      <Box
        sx={{
          pt: '32px',
        }}
      >
        <Box>
          <ShopHeader shopName={storeInfo.store_name || ''} />
        </Box>
        <Box
          sx={{
            maxWidth: 327,
            margin: 'auto',
          }}
        >
          <Box sx={{ mt: 4 }}>
            <Box
              sx={{
                backgroundColor: '#fff',
                borderRadius: 4,
                position: 'relative',
                pt: 4,
                pl: 8,
                pr: 8,
                pb: mode !== TopMode.adjustment ? 4 : 6,
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  top: 12,
                  right: 12,
                  width: '45px',
                }}
              >
                <IconButton
                  sx={{
                    '& > svg g circle': {
                      stroke:
                        mode === TopMode.top
                          ? '#CDAF9D'
                          : mode === TopMode.buy
                          ? '#B5DCC9'
                          : '#BDD6EA',
                    },
                    '& > svg g path': {
                      fill:
                        mode === TopMode.top
                          ? '#CDAF9D'
                          : mode === TopMode.buy
                          ? '#B5DCC9'
                          : '#BDD6EA',
                    },
                  }}
                  onClick={() => {
                    setOpen(true);
                    setModalIndex(0);
                  }}
                >
                  <InfoIcon />
                </IconButton>
              </Box>
              <Box>
                <Typography
                  fontWeight={'bold'}
                  fontSize={22}
                  lineHeight={32 / 22}
                  textAlign="center"
                  pt="1px"
                >
                  スマート受付
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'grid',
                  gap: 3,
                  mt: 4,
                }}
              >
                {mode === TopMode.top && (
                  <>
                    <Box>
                      <TopNavigationButton
                        labelColor="#ffffff"
                        backgroundColor="#CDAF9D"
                        onClick={() => setMode(TopMode.buy)}
                      >
                        購入・レンズ交換
                      </TopNavigationButton>
                    </Box>
                    <Box>
                      <TopNavigationButton
                        labelColor="#ffffff"
                        backgroundColor="#CDAF9D"
                        onClick={() => setMode(TopMode.adjustment)}
                      >
                        調整・保証・相談
                      </TopNavigationButton>
                    </Box>
                  </>
                )}
                {mode === TopMode.buy && (
                  <Box>
                    <TopNavigationButton
                      labelColor="#ffffff"
                      backgroundColor="#53B385"
                      big={true}
                      onClick={() => navigateToInterview(SituationType.Buy)}
                    >
                      受付開始
                    </TopNavigationButton>
                  </Box>
                )}
                {mode === TopMode.adjustment && (
                  <>
                    <Box>
                      <TopNavigationButton
                        labelColor="#ffffff"
                        backgroundColor="#316B98"
                        onClick={() =>
                          navigateToStandby(SituationType.Adjustment)
                        }
                      >
                        お持ちのメガネの調整
                      </TopNavigationButton>
                    </Box>
                    <Box>
                      <TopNavigationButton
                        labelColor="#ffffff"
                        backgroundColor="#316B98"
                        onClick={() =>
                          navigateToInterview(SituationType.Warranty)
                        }
                      >
                        お持ちのメガネの保証
                      </TopNavigationButton>
                    </Box>
                    <Box>
                      <TopNavigationButton
                        labelColor="#ffffff"
                        backgroundColor="#316B98"
                        onClick={() =>
                          navigateToInterview(SituationType.Consultation)
                        }
                      >
                        その他・スタッフ相談
                      </TopNavigationButton>
                    </Box>
                  </>
                )}
              </Box>
              {mode !== TopMode.adjustment && (
                <Box
                  sx={{
                    mt: 4,
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'space-between',
                    pt: 2,
                    pb: 2,
                    '&::before': {
                      content: "''",
                      display: 'block',
                      position: 'absolute',
                      width: '2px',
                      height: '100%',
                      left: 0,
                      right: 0,
                      top: 0,
                      margin: 'auto',
                      backgroundColor:
                        mode === TopMode.top ? '#F3E8E0' : '#C7E4D6',
                      borderRadius: '1px',
                    },
                  }}
                >
                  <Box>
                    <TopWaitingTimeForEyeTest
                      waitingTime={
                        storeInfo.data?.store_waiting_time !== undefined
                          ? storeInfo.data.store_waiting_time + ''
                          : '-'
                      }
                      waitingCount={
                        storeInfo.data?.store_a_waiting_people !== undefined
                          ? storeInfo.data.store_a_waiting_people + ''
                          : '-'
                      }
                      closed={
                        storeInfo.data?.store_reception_status === '2'
                          ? true
                          : false
                      }
                    ></TopWaitingTimeForEyeTest>
                  </Box>
                  <Box>
                    <TopWaitingTimeForFinishing
                      waitingTime={
                        storeInfo.data?.store_take_lens_time !== undefined
                          ? storeInfo.data.store_take_lens_time + ''
                          : '-'
                      }
                      closed={
                        storeInfo.data?.store_process_status === '1'
                          ? true
                          : false
                      }
                    ></TopWaitingTimeForFinishing>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
          {mode !== TopMode.buy && (
            <Box sx={{ mt: 2 }}>
              <TopWaitingNumberForAdjustment
                waitingCount={
                  storeInfo.data?.store_f_waiting_people !== undefined
                    ? storeInfo.data.store_f_waiting_people + ''
                    : '-'
                }
              ></TopWaitingNumberForAdjustment>
            </Box>
          )}
          <Box
            sx={{
              mt: 2,
            }}
          >
            <Typography
              sx={{
                fontSize: 10,
                lineHeight: 15 / 10,
                textAlign: 'center',
                color:
                  mode === TopMode.top
                    ? '#9F8A7E'
                    : mode === TopMode.buy
                    ? '#509272'
                    : '#316B98',
              }}
            >
              ※人数や時間は大まかな目安です。順番が前後することがあります。
            </Typography>
          </Box>
          {mode === TopMode.buy && (
            <Box
              sx={{
                mt: 8,
              }}
            >
              <Typography
                sx={{
                  fontSize: 14,
                  lineHeight: 20 / 14,
                  color: '#000000',
                  textAlign: 'center',
                }}
              >
                調整・保証・相談は
                <Link
                  component="a"
                  display={'inline-block'}
                  ml={1}
                  sx={{ color: '#000', textDecorationColor: '#000' }}
                  onClick={() => changeTopMode(TopMode.adjustment)}
                >
                  こちら
                </Link>
              </Typography>
            </Box>
          )}
          {mode === TopMode.adjustment && (
            <Box
              sx={{
                mt: 8,
              }}
            >
              <Typography
                sx={{
                  fontSize: 14,
                  lineHeight: 20 / 14,
                  color: '#000000',
                  textAlign: 'center',
                }}
              >
                メガネ購入・レンズ交換は
                <Link
                  component="a"
                  display={'inline-block'}
                  ml={1}
                  sx={{ color: '#000', textDecorationColor: '#000' }}
                  onClick={() => changeTopMode(TopMode.buy)}
                >
                  こちら
                </Link>
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      {/* このアプリでできること */}
      <IntroductionModal
        open={open}
        onClose={handleClose}
        index={modalIndex}
        onChangeIndex={changeIndexHandle}
      ></IntroductionModal>
      <RequestCompleteModal
        open={modalOpen}
        type={'f'}
        onClose={() => {
          setModalOpen(false);
          setLineNotificationModalOpen(true);
        }}
      ></RequestCompleteModal>
      <LineNotificationModal
        open={lineNotificationModalOpen}
        onClose={() => {
          setLineNotificationModalOpen(false);
          nextHandle();
        }}
      ></LineNotificationModal>
      <ErrorModal
        open={errorModalOpen}
        onClose={() => {
          toActiveStatusPage();
          setErrorModalOpen(false);
        }}
        message1={errorModalText}
      ></ErrorModal>
      <CompleteModal
        open={completedModalOpen}
        onClose={() => {
          toActiveStatusPage();
          setCompletedModalOpen(false);
        }}
      ></CompleteModal>
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: 9999,
        }}
        open={loadingOpen}
      >
        <Loading />
      </Backdrop>
    </Box>
  ) : (
    <>
      <Box
        sx={{
          mt: '-7px',
          '& svg': {
            display: 'block',
            width: '100%',
            height: 'auto',
          },
        }}
      >
        <BGImage3 />
      </Box>
      <Backdrop
        sx={{
          background: 'rgba(0, 0, 0, 0)',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={true}
      >
        <Loading />
      </Backdrop>
    </>
  );
};
export default Top;
