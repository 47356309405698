import { Box, Link, MenuItem, Typography } from '@mui/material';
import CheckBox from 'components/form/Checkbox';
import RadioBox from 'components/form/RadioBox';
import SelectBox from 'components/form/SelectBox';
import TextBox from 'components/form/TextBox';
import NavigationButton from 'components/NavigationButton';
import ShopHeader from 'components/ShopHeader';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'store';
import {
  ContactType,
  setInterview,
  SituationType,
  UseCaseType,
} from 'store/requestSlice';
import { httpsCallable } from 'firebase/functions';
import { functions } from 'utils/firebase';
import LineNotificationModal from 'components/modal/LineNotificationModal';
import RequestCompleteModal from 'components/modal/RequestCompleteModal';
import ErrorModal from 'components/modal/ErrorModal';
import { ReactComponent as Loading } from 'assets/images/common/LINE_spinner_light.svg';
import Backdrop from '@mui/material/Backdrop/Backdrop';
import { setErrorInfo } from 'store/errorSlice';
import { dateFormat, addMinutes } from '../../utils/datetime';
import { pushExtStatus, setCallCode } from 'store/receiptSlice';
import { pushDataLayer } from '../../utils/gtm';

type FormInput = {
  telephoneNumber?: string;
  name?: string;
  privacy: boolean;
  eyesightTest?: boolean;
  contactType?: ContactType;
  useCaseType?: UseCaseType;
};

const Interview = () => {
  const userInfo = useSelector((state: RootState) => state.user);
  const storeInfo = useSelector((state: RootState) => state.store);
  const [age, setAge] = useState(20);
  const [number, setNumber] = useState(1);
  const [eyesightTestOptionVisible, setEyesightTestOptionVisible] =
    useState(false);
  const [contactMessageVisible, setContactMessageVisible] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [lineNotificationModalOpen, setLineNotificationModalOpen] =
    useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [errorModalText, setErrorModalText] = useState('');
  const [loadingOpen, setLoadingOpen] = useState(false);
  const {
    handleSubmit,
    getValues,
    control,
    formState: { errors, isDirty, isValid },
  } = useForm<FormInput>({
    mode: 'onChange',
    defaultValues: {
      telephoneNumber: '',
      name: '',
      privacy: false,
      eyesightTest: undefined,
      contactType: undefined,
      useCaseType: undefined,
    },
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selector = useSelector((state: RootState) => state.request);

  const nextHandle = () => {
    navigate('/standby/wait-datasync');
  };
  function onSubmit(data: FormInput) {
    if (data.eyesightTest && data.contactType === undefined)
      throw new Error('コンタクト装着の有無が入力されていません');
    if (data.eyesightTest && data.useCaseType === undefined)
      throw new Error('メガネの利用目的が入力されていません');

    const now = dateFormat(new Date(), 'hh:mm');
    if (
      //      now < (process.env.REACT_APP_STORE_OPEN_TIME || '10:00') ||
      //      now > (process.env.REACT_APP_STORE_CLOSE_TIME || '22:00')
      now < storeInfo.checkin_start_at ||
      now > storeInfo.checkin_end_at
    ) {
      dispatch(
        setErrorInfo({
          body1: 'サービス時間外です。店舗の営業時間内のみご利用いただけます。',
        }),
      );
      navigate('/error');
    } else {
      try {
        setLoadingOpen(true);
        console.log(
          dateFormat(new Date(), 'YYYY-MM-DDThh:mm:ss+0900'),
          'lambda-post_receptions...',
        );
        const postReceptions = httpsCallable(
          functions,
          'lambda-post_receptions',
        );
        const postData = {
          channel_id: process.env.REACT_APP_LINE_CHANNEL_ID,
          line_id: userInfo.uid,
          store_code: storeInfo.store_code,
          repair_kbn:
            selector.situation === SituationType.Warranty
              ? '1'
              : selector.situation === SituationType.Consultation
              ? '2'
              : '0',
          line_purpose: data.eyesightTest ? 'A' : 'B',
          client_name: data.name,
          tel_num: data.telephoneNumber,
          age: age,
          glasses_num:
            selector.situation === SituationType.Warranty ||
            selector.situation === SituationType.Consultation
              ? 1
              : number,
          issues_answer_list: data.eyesightTest
            ? [
                {
                  issues_code: 'FIXED01',
                  answer_code:
                    data.contactType === ContactType.Contact ? 'C1' : 'C2',
                },
                {
                  issues_code: 'JP001',
                  answer_code:
                    data.useCaseType === UseCaseType.Everyday
                      ? 'JP0011'
                      : data.useCaseType === UseCaseType.Myopia
                      ? 'JP0012'
                      : data.useCaseType === UseCaseType.Bifocals
                      ? 'JP0013'
                      : data.useCaseType === UseCaseType.Consultation
                      ? 'JP0014'
                      : '',
                },
              ]
            : undefined,
        };
        if (!storeInfo.required_name) delete postData.client_name;
        if (!storeInfo.required_tel) delete postData.tel_num;
        postReceptions(postData).then((res: any) => {
          console.log(dateFormat(new Date(), 'YYYY-MM-DDThh:mm:ss+0900'), res);
          if (res.data.status_code === 200 && res.data.body.return_code === 0) {
            pushDataLayer({
              event: 'liff_check_in',
            });
            dispatch(
              setInterview({
                age: age,
                number: number,
                name: data.name,
                TelephoneNumber: data.telephoneNumber,
                eyesightTest: data.eyesightTest
                  ? {
                      contact: data.contactType
                        ? data.contactType
                        : ContactType.Contact,
                      useCase: data.useCaseType
                        ? data.useCaseType
                        : UseCaseType.Bifocals,
                    }
                  : undefined,
              }),
            );
            const at = dateFormat(
              addMinutes(new Date(), -9 * 60),
              'YYYY-MM-DDThh:mm:ss.000Z',
            );
            dispatch(setCallCode(res.data.body.data.call_code));
            for (const recp of res.data.body.data.receipt_no) {
              dispatch(
                pushExtStatus({
                  receipt_no: recp,
                  line_user_id: userInfo.uid || '',
                  ext_state: data.eyesightTest ? '100_prep' : '200_prep',
                  be_updated_at: at,
                }),
              );
            }
            setModalOpen(true);
            setLoadingOpen(false);
          } else {
            setErrorModalText(
              res.data.body.return_message ||
                '現在スマートチェックインを利用できません。スタッフにお声かけください。',
            );
            setErrorModalOpen(true);
            setLoadingOpen(false);
          }
        });
      } catch (e) {
        setErrorModalText(
          '現在スマートチェックインを利用できません。スタッフにお声かけください。',
        );
        setErrorModalOpen(true);
        setLoadingOpen(false);
      }
    }
  }

  return (
    <Box
      sx={{
        bgcolor: '#C7E4D6',
        pl: 4,
        pr: 4,
        pb: 12,
        pt: 12,
      }}
    >
      <Box>
        <ShopHeader shopName={storeInfo.store_name || ''} />
      </Box>
      <form
        onSubmit={handleSubmit(onSubmit)}
        onChange={() => {
          getValues();
        }}
      >
        <Box
          sx={{
            pl: 2,
            pr: 2,
          }}
        >
          <Box sx={{ mt: 4 }}>
            <Typography
              sx={{
                textAlign: 'center',
                fontSize: 14,
                lineHeight: 20 / 14,
                color: '#000000',
                fontWeight: 500,
              }}
            >
              以下の質問にお答えください。
            </Typography>
          </Box>
          <Box sx={{ mt: 6 }}>
            <Box>
              <Typography fontSize={14} lineHeight={20 / 14} fontWeight={500}>
                視力測定
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                columnGap: '13px',
                rowGap: '13px',
                mt: 2,
              }}
            >
              <Controller
                name="eyesightTest"
                control={control}
                rules={{
                  validate: (value) => value === true || value === false,
                }}
                render={({ field }) => {
                  return (
                    <>
                      <Box>
                        <RadioBox
                          value={field.value === true}
                          onChange={(e) => {
                            field.onChange(true);
                            setEyesightTestOptionVisible(true);
                          }}
                          disabled={
                            storeInfo.data?.store_reception_status === '2'
                              ? true
                              : false
                          }
                        >
                          希望する
                        </RadioBox>
                      </Box>
                      <Box>
                        <RadioBox
                          value={field.value === false}
                          onChange={(e) => {
                            field.onChange(false);
                            setEyesightTestOptionVisible(false);
                          }}
                        >
                          希望しない
                        </RadioBox>
                      </Box>
                    </>
                  );
                }}
              ></Controller>
            </Box>
            {storeInfo.data?.store_reception_status === '2' && (
              <Box sx={{ mt: 4 }}>
                <Typography
                  sx={{
                    fontSize: 16,
                    lineHeight: 20 / 14,
                    color: '#EE4D4D',
                    fontWeight: 500,
                  }}
                >
                  本日の測定受付は終了しました。
                </Typography>
              </Box>
            )}
          </Box>
          {eyesightTestOptionVisible && (
            <Box sx={{ mt: 6 }}>
              <Box>
                <Box>
                  <Box>
                    <Typography
                      fontSize={14}
                      lineHeight={20 / 14}
                      fontWeight={500}
                    >
                      本日のコンタクトの装着
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'grid',
                      gridTemplateColumns: '1fr 1fr',
                      columnGap: '13px',
                      rowGap: '13px',
                      mt: 2,
                    }}
                  >
                    <Controller
                      name="contactType"
                      control={control}
                      rules={{
                        required: getValues('eyesightTest'),
                      }}
                      render={({ field }) => {
                        return (
                          <>
                            <Box>
                              <RadioBox
                                value={field.value === ContactType.Contact}
                                onChange={(e) => {
                                  e && field.onChange(ContactType.Contact);
                                  setContactMessageVisible(true);
                                }}
                              >
                                あり
                              </RadioBox>
                            </Box>
                            <Box>
                              <RadioBox
                                value={field.value === ContactType.None}
                                onChange={(e) => {
                                  e && field.onChange(ContactType.None);
                                  setContactMessageVisible(false);
                                }}
                              >
                                なし
                              </RadioBox>
                            </Box>
                          </>
                        );
                      }}
                    ></Controller>
                  </Box>
                  {contactMessageVisible && (
                    <Box sx={{ mt: 4 }}>
                      <Typography
                        sx={{
                          fontSize: 16,
                          lineHeight: 20 / 14,
                          color: '#EE4D4D',
                          fontWeight: 500,
                        }}
                      >
                        測定視力ご案内予定時刻の5分前にコンタクトを外してお待ちください。
                      </Typography>
                    </Box>
                  )}
                </Box>
                <Box sx={{ mt: 6 }}>
                  <Box>
                    <Typography
                      fontSize={14}
                      lineHeight={20 / 14}
                      fontWeight={500}
                    >
                      メガネの利用目的
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'grid',
                      gridTemplateColumns: '1fr 1fr',
                      columnGap: '13px',
                      rowGap: '13px',
                      mt: 2,
                    }}
                  >
                    <Controller
                      name="useCaseType"
                      control={control}
                      rules={{ required: getValues('eyesightTest') }}
                      render={({ field }) => {
                        return (
                          <>
                            <Box>
                              <RadioBox
                                value={field.value === UseCaseType.Everyday}
                                onChange={(e) =>
                                  e && field.onChange(UseCaseType.Everyday)
                                }
                              >
                                日常用
                                <Typography sx={{ fontSize: 10 }}>
                                  （遠用）
                                </Typography>
                              </RadioBox>
                            </Box>
                            <Box>
                              <RadioBox
                                value={field.value === UseCaseType.Myopia}
                                onChange={(e) =>
                                  e && field.onChange(UseCaseType.Myopia)
                                }
                              >
                                手元用
                                <Typography sx={{ fontSize: 10 }}>
                                  （近用）
                                </Typography>
                              </RadioBox>
                            </Box>
                            <Box>
                              <RadioBox
                                value={field.value === UseCaseType.Bifocals}
                                onChange={(e) =>
                                  e && field.onChange(UseCaseType.Bifocals)
                                }
                              >
                                遠近両用
                              </RadioBox>
                            </Box>
                            <Box>
                              <RadioBox
                                value={field.value === UseCaseType.Consultation}
                                onChange={(e) =>
                                  e && field.onChange(UseCaseType.Consultation)
                                }
                              >
                                その他
                              </RadioBox>
                            </Box>
                          </>
                        );
                      }}
                    ></Controller>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
          {selector.situation !== SituationType.Warranty &&
            selector.situation !== SituationType.Consultation && (
              <Box
                sx={{
                  display: 'flex',
                  mt: 6,
                }}
              >
                <Box
                  sx={{
                    flexGrow: 1,
                    pt: 4,
                  }}
                >
                  <Typography
                    fontSize={14}
                    lineHeight={20 / 14}
                    fontWeight={500}
                  >
                    メガネの作成本数
                  </Typography>
                </Box>
                <Box
                  sx={{
                    flexShrink: 0,
                    flexGrow: 0,
                    width: 100,
                  }}
                >
                  <SelectBox
                    value={number}
                    onChange={(e) => setNumber(e.target.value as number)}
                  >
                    {['1本', '2本', '3本', '4本', '5本以上'].map((v, i) => {
                      return (
                        <MenuItem key={i + 1} value={i + 1}>{`${v}`}</MenuItem>
                      );
                    })}
                  </SelectBox>
                </Box>
              </Box>
            )}
          {storeInfo.required_name && (
            <Box sx={{ mt: 6 }}>
              <Box>
                <Typography fontSize={14} lineHeight={20 / 14} fontWeight={500}>
                  お名前(カタカナ)
                </Typography>
              </Box>
              <Box sx={{ mt: 1 }}>
                <TextBox
                  name="name"
                  placeholder="タナカタロウ"
                  control={control}
                  rules={{
                    required: true,
                    maxLength: {
                      value: 20,
                      message: '20文字以内で入力してください。',
                    },
                    pattern: {
                      value: /^[ァ-ワヲンーA-Za-z \u3000]+$/,
                      message: 'お名前はカタカナで入力してください。',
                    },
                  }}
                ></TextBox>
                <Box>
                  <Typography sx={{ mt: 2, fontSize: 12, color: '#EE4D4D' }}>
                    {errors.name?.message}
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
          <Box
            sx={{
              display: 'flex',
              mt: 6,
            }}
          >
            <Box
              sx={{
                flexGrow: 1,
                pt: 4,
              }}
            >
              <Typography fontSize={14} lineHeight={20 / 14} fontWeight={500}>
                ご年齢
              </Typography>
            </Box>
            <Box
              sx={{
                flexShrink: 0,
                flexGrow: 0,
                width: 100,
              }}
            >
              <SelectBox
                value={age}
                onChange={(e) => setAge(e.target.value as number)}
              >
                {[...Array(100)].map((_, i) => {
                  return (
                    <MenuItem key={i + 1} value={i + 1}>{`${
                      i + 1
                    }才`}</MenuItem>
                  );
                })}
              </SelectBox>
            </Box>
          </Box>
          {storeInfo.required_tel && (
            <Box
              sx={{
                mt: 6,
              }}
            >
              <Box>
                <Typography fontSize={14} lineHeight={20 / 14} fontWeight={500}>
                  電話番号
                </Typography>
              </Box>
              <Box sx={{ mt: 1 }}>
                <TextBox
                  name="telephoneNumber"
                  placeholder="09011111111"
                  control={control}
                  rules={{
                    required: true,
                    pattern: {
                      value: /^0\d{9,10}$/,
                      message: '半角でハイフンなしで入力してください。',
                    },
                  }}
                ></TextBox>
                <Box>
                  <Typography sx={{ mt: 2, fontSize: 12, color: '#EE4D4D' }}>
                    {errors.telephoneNumber?.message}
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: 6,
            }}
          >
            <Controller
              name="privacy"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) => {
                return (
                  <CheckBox
                    onChange={(event) => field.onChange(event.target.checked)}
                    label={
                      <Typography
                        sx={{
                          fontSize: 14,
                          lineHeight: 21 / 14,
                          color: '#000000',
                        }}
                      >
                        <Link
                          sx={{
                            color: '#ffffff',
                            textDecorationColor: '#ffffff',
                          }}
                          target={'_blank'}
                          href="https://www.jins.com/jp/about/policy/"
                        >
                          個人情報収集規約
                        </Link>
                        に同意する
                      </Typography>
                    }
                  ></CheckBox>
                );
              }}
            ></Controller>
          </Box>
        </Box>
        <Box
          sx={{
            pt: 10,
            pb: 16,
          }}
        >
          <Box sx={{ width: '280px', m: 'auto' }}>
            <NavigationButton
              labelColor="#ffffff"
              backgroundColor="#1C573B"
              disabledLabelColor="#ffffff"
              disabledBackgroundColor="#A0A0A0"
              disabled={!isDirty || !isValid}
              type="submit"
            >
              受付する
            </NavigationButton>
          </Box>
        </Box>
      </form>
      <RequestCompleteModal
        open={modalOpen}
        type={'a'}
        onClose={() => {
          setModalOpen(false);
          setLineNotificationModalOpen(true);
        }}
      ></RequestCompleteModal>
      <LineNotificationModal
        open={lineNotificationModalOpen}
        onClose={() => {
          setLineNotificationModalOpen(false);
          nextHandle();
        }}
      ></LineNotificationModal>
      <ErrorModal
        open={errorModalOpen}
        onClose={() => {
          setErrorModalOpen(false);
        }}
        message1={errorModalText}
      ></ErrorModal>
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: 9999,
        }}
        open={loadingOpen}
      >
        <Loading />
      </Backdrop>
    </Box>
  );
};
export default Interview;
