import { Box, Typography } from '@mui/material';
import ShopHeader from 'components/ShopHeader';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { ReactComponent as BGImage } from 'assets/images/error/bg-image.svg';

export default function Error() {
  const storeInfo = useSelector((state: RootState) => state.store);
  const errorInfo = useSelector((state: RootState) => state.error);
  const titleText = errorInfo
    ? errorInfo.title
      ? errorInfo.title
      : undefined
    : undefined;
  const bodyText1 = errorInfo
    ? errorInfo.body1
      ? errorInfo.body1
      : undefined
    : 'エラーが発生しました。';
  const bodyText2 = errorInfo
    ? errorInfo.body2
      ? errorInfo.body2
      : undefined
    : 'お近くのスタッフにお声がけください。';

  return (
    <Box sx={{ backgroundColor: '#E9FDF9', minHeight: '100vh' }}>
      <Box
        sx={{
          backgroundColor: '#c7e4d6',
          background:
            'linear-gradient(180deg, rgba(199,228,214,1) 0%, rgba(199,228,214,1) 98%, rgba(233,253,249,1) 99%, rgba(233,253,249,1) 100%)',
          pt: 6,
        }}
      >
        <Box sx={{ pl: 4, pr: 4 }}>
          <Box>
            <ShopHeader shopName={storeInfo.store_name || ''} />
          </Box>
          <Box sx={{ mt: 4 }}>
            <Box
              sx={{
                backgroundColor: '#ffffff',
                borderRadius: '15px',
                paddingTop: 6,
                pl: 4,
                pr: 4,
                pb: 6,
              }}
            >
              {titleText && (
                <Box>
                  <Typography
                    textAlign={'center'}
                    sx={{
                      fontSize: 24,
                      lineHeight: 35 / 24,
                      fontWeight: 500,
                      color: '#000000',
                    }}
                  >
                    {titleText}
                  </Typography>
                </Box>
              )}
              <Box sx={{ mt: '3px' }}>
                <Typography
                  textAlign={'center'}
                  sx={{
                    fontSize: 14,
                    lineHeight: 20 / 14,
                    fontWeight: 500,
                    color: '#000000',
                  }}
                >
                  {bodyText1}
                  <br />
                  {bodyText2}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            mt: 21,
            '& > svg': {
              display: 'block',
              width: '100%',
              height: 'auto',
            },
          }}
        >
          <BGImage></BGImage>
        </Box>
      </Box>
    </Box>
  );
}
