import { Box, Typography } from '@mui/material';
import Modal, { ModalProps } from 'components/Modal';
import NavigationButton from 'components/NavigationButton';
import React from 'react';
import { ReactComponent as Image } from 'assets/images/modal/request-complete-modal__image.svg';

export type RequestCompleteModalProps = Omit<ModalProps, 'children'> & {
  type: 'a' | 'b' | 'f';
};
export default function RequestCompleteModal(props: RequestCompleteModalProps) {
  return (
    <Modal {...props}>
      <Box
        sx={{ position: 'relative', pt: props.type === 'f' ? 12 : 10, pb: 10 }}
      >
        <Box>
          <Typography
            textAlign={'center'}
            sx={{
              fontSize: 20,
              lineHeight: 29 / 20,
              color: '#000000',
              fontWeight: 700,
            }}
          >
            受付が完了しました！
          </Typography>
        </Box>
        {props.type !== 'f' && (
          <Box sx={{ mt: 3, pl: 4, pr: 4 }}>
            <Typography
              textAlign={'center'}
              sx={{ fontSize: 14, lineHeight: 20 / 14, color: '#000000' }}
            >
              購入商品がお決まりの場合は商品をスタッフにお渡しください。
            </Typography>
          </Box>
        )}
        <Box
          sx={{
            width: '249px',
            margin: 'auto',
            mt: props.type === 'f' ? 11 : 6,
            '> svg': {
              display: 'block',
              width: '100%',
              height: 'auto',
            },
          }}
        >
          <Image></Image>
        </Box>
        <Box
          sx={{
            margin: 'auto',
            mt: 6,
            width: '220px',
          }}
        >
          <NavigationButton
            backgroundColor="#E0E0E0"
            labelColor="#000000"
            onClick={() => {
              props.onClose && props.onClose({}, 'backdropClick');
            }}
          >
            閉じる
          </NavigationButton>
        </Box>
      </Box>
    </Modal>
  );
}
