import {
  Box,
  Modal as MUIModal,
  ModalProps as MUIModalProps,
} from '@mui/material';
import React from 'react';
import { RemoveScroll } from 'react-remove-scroll';

export type ModalProps = MUIModalProps;
export default function Modal({ children, ...props }: ModalProps) {
  return (
    <RemoveScroll enabled={props.open}>
      <MUIModal {...props} disableAutoFocus>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '24px',
            right: '24px',
            transform: 'translate(0, -50%)',
            bgcolor: '#fff',
            borderRadius: '10px',
          }}
        >
          {children}
        </Box>
      </MUIModal>
    </RemoveScroll>
  );
}
