import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ErrorInfo {
  title?: string | undefined;
  body1?: string | undefined;
  body2?: string | undefined;
}

const initialState: ErrorInfo = {};

const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    setErrorInfo: (state: ErrorInfo, action: PayloadAction<ErrorInfo>) => {
      state.title = action.payload.title;
      state.body1 = action.payload.body1;
      state.body2 = action.payload.body2;
    },
  },
});

export const { setErrorInfo } = errorSlice.actions;

export default errorSlice.reducer;
