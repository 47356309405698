import { Box, Typography } from '@mui/material';
import React from 'react';

import { ReactComponent as Logo } from 'assets/images/common/logo.svg';

type TopShopHeaderProps = {
  shopName: string;
};

const ShopHeader = (props: TopShopHeaderProps) => {
  return (
    <Box sx={{}}>
      <Box
        sx={{
          width: '140px',
          margin: 'auto',
          '& svg': { display: 'block', width: '100%', height: 'auto' },
        }}
      >
        <Logo />
      </Box>
      <Box>
        <Typography
          sx={{
            fontSize: '16px',
            lineHeight: 24 / 16,
            mt: 2,
            fontWeight: 500,
          }}
          textAlign="center"
        >
          {props.shopName}
        </Typography>
      </Box>
    </Box>
  );
};
export default ShopHeader;
