import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface UserInfo {
  uid: string | undefined;
  display_name: string | undefined;
  is_show_introduction: boolean;
  receipt_no: string | undefined;
  action: string | undefined;
  message_id: string | undefined;
  cancel_shop_code: string | undefined;
  lcid: string | undefined;
  fid: string | undefined;
}

const initialState: UserInfo = {
  uid: undefined,
  display_name: undefined,
  is_show_introduction: true,
  receipt_no: undefined,
  action: undefined,
  message_id: undefined,
  cancel_shop_code: undefined,
  lcid: undefined,
  fid: undefined,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserInfo: (
      state: UserInfo,
      action: PayloadAction<{
        uid: string;
        display_name: string | undefined;
        receipt_no: string | undefined;
        action: string | undefined;
        message_id: string | undefined;
        cancel_shop_code: string | undefined;
        lcid: string | undefined;
        fid: string | undefined;
      }>,
    ) => {
      state.uid = action.payload.uid;
      state.display_name = action.payload.display_name;
      state.receipt_no = action.payload.receipt_no;
      state.action = action.payload.action;
      state.message_id = action.payload.message_id;
      state.cancel_shop_code = action.payload.cancel_shop_code;
      state.lcid = action.payload.lcid;
      state.fid = action.payload.fid;
    },
    setIsShowIntroduction: (state: UserInfo) => {
      state.is_show_introduction = true;
    },
    clearAction: (state: UserInfo) => {
      state.action = undefined;
    },
  },
});

export const { setUserInfo, setIsShowIntroduction, clearAction } =
  userSlice.actions;

export default userSlice.reducer;
