import Interview from 'pages/interview';
import CallAcceptance from 'pages/standby/CallAcceptance';
import CallAdjustment from 'pages/standby/CallAdjustment';
import CallInterview from 'pages/standby/CallInterview';
import CallMeasurement from 'pages/standby/CallMeasurement';
import WaitAcceptance from 'pages/standby/WaitAcceptance';
import WaitAdjustment from 'pages/standby/WaitAdjustment';
import WaitInterview from 'pages/standby/WaitInterview';
import WaitMeasurement from 'pages/standby/WaitMeasurement';
import WaitDataSync from 'pages/standby/WaitDataSync';
import Error from 'pages/error';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import liff from '@line/liff';
import { useEffect, useState } from 'react';
import { RootState } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import { setUserInfo } from 'store/userSlice';
import { setStoreCode } from 'store/storeSlice';
import {
  pushUserReceipt,
  updateUserReceipt,
  removeUserReceipt,
  pushReceipt,
  updateReceipt,
  removeReceipt,
  clearReceipt,
  pushExtStatus,
  updateExtStatus,
  removeExtStatus,
  UserReceiptData,
  ReceiptData,
  ExtStatusData,
} from 'store/receiptSlice';
import { httpsCallable } from 'firebase/functions';
import { auth, functions } from 'utils/firebase';
import { signInWithCustomToken } from 'firebase/auth';
import { firestore } from 'utils/firebase';
import { query, where, onSnapshot, collection } from 'firebase/firestore';
import { ReactComponent as Loading } from 'assets/images/common/LINE_spinner_light.svg';
import Backdrop from '@mui/material/Backdrop/Backdrop';
import ErrorModal from 'components/modal/ErrorModal';
import { pushDataLayer } from './utils/gtm';
import Top, { TopMode } from './pages/top';
import { Box } from '@mui/material';
import { ReactComponent as BGImage } from 'assets/images/common/bg.svg';

// const testInterview = {
//   age: 20,
//   name: 'test',
//   number: 1,
//   TelephoneNumber: '09011111111',
// };

// const testInterview_HasContact = {
//   ...testInterview,
//   eyesightTest: {
//     contact: ContactType.Glasses,
//     useCase: UseCaseType.Everyday,
//   },
// };

const App = () => {
  const userInfo = useSelector((state: RootState) => state.user);
  const [isLoading, setLoading] = useState(true);
  const [isLogin, setLogin] = useState(false);
  const [isError, setError] = useState(false);
  const dispatch = useDispatch();

  const q: any = window.location.search
    .slice(1)
    .split('&')
    .map((p) => p.split('='))
    .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {});
  const store_code = q.store_code;
  const receipt_no = q.receipt_no;
  const action = q.action;
  const message_id = q.message_id;
  const cancel_shop_code = q.cancel_shop_code;

  useEffect(() => {
    console.log('Login...');
    const f = async () => {
      try {
        // LINEアクセストークンをチェックして、Firebaseトークンを取得
        const accessToken = liff.getAccessToken();
        const idToken = liff.getIDToken();
        const verifyToken = httpsCallable(functions, 'verify-token');
        const resVerifyToken: any = await verifyToken({
          token: accessToken,
        });
        const firebaseToken = resVerifyToken.data.firebase_token;

        // Firebaseへログイン
        const resSignIn = await signInWithCustomToken(auth, firebaseToken);
        const user_info = {
          uid: resSignIn.user.uid.split(':').slice(-1)[0],
          display_name: resSignIn.user.displayName || undefined,
          receipt_no: receipt_no || undefined,
          action: action || undefined,
          message_id: message_id || undefined,
          cancel_shop_code: cancel_shop_code || undefined,
          lcid: process.env.REACT_APP_LINE_CHANNEL_ID,
          fid: resSignIn.user.uid,
        };
        dispatch(setUserInfo(user_info));
        pushDataLayer({
          event: 'initialize',
        });
        if (message_id) {
          pushDataLayer({
            event: 'notification_open',
          });
        }
        dispatch(setStoreCode(store_code));
        setLogin(true);

        for (let i = 1; i <= 3; i++) {
          try {
            // トークンを連携
            const userTokens = httpsCallable(functions, 'lambda-user_tokens');
            const resUserTokens: any = await userTokens({
              channel_id: process.env.REACT_APP_LINE_CHANNEL_ID,
              line_id: user_info.uid,
              id_token: idToken,
              access_token: accessToken,
            });
            console.log(resUserTokens);
            break;
          } catch {
            if (i === 3) console.log('lambda-user_tokens Error!');
          }
        }
      } catch {
        setError(true);
      }
    };
    f();
  }, []);

  useEffect(() => {
    if (isLogin) {
      console.log('Loading...');

      dispatch(clearReceipt());

      const q3 = query(
        collection(firestore, '/ext-state'),
        where('line_user_id', '==', userInfo.uid),
      );
      const unsubscribe3 = onSnapshot(q3, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          const extStatusData = change.doc.data();
          if (change.type === 'added') {
            dispatch(pushExtStatus(extStatusData as ExtStatusData));
          }
          if (change.type === 'modified') {
            dispatch(updateExtStatus(extStatusData as ExtStatusData));
          }
          if (change.type === 'removed') {
            dispatch(removeExtStatus(extStatusData as ExtStatusData));
          }
        });
      });

      const q2 = query(
        collection(firestore, '/recp-state'),
        where('line_user_id', '==', userInfo.uid),
      );
      const unsubscribe2 = onSnapshot(q2, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          const receiptData = change.doc.data();
          if (change.type === 'added') {
            dispatch(pushReceipt(receiptData as ReceiptData));
          }
          if (change.type === 'modified') {
            dispatch(updateReceipt(receiptData as ReceiptData));
          }
          if (change.type === 'removed') {
            dispatch(removeReceipt(receiptData as ReceiptData));
          }
        });
      });

      const q1 = query(
        collection(firestore, '/user-recp'),
        where('line_user_id', '==', userInfo.uid),
      );
      const unsubscribe1 = onSnapshot(q1, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          const userReceiptData = change.doc.data();
          if (change.type === 'added') {
            dispatch(pushUserReceipt(userReceiptData as UserReceiptData));
          }
          if (change.type === 'modified') {
            dispatch(updateUserReceipt(userReceiptData as UserReceiptData));
          }
          if (change.type === 'removed') {
            dispatch(removeUserReceipt(userReceiptData as UserReceiptData));
          }
        });
      });

      console.log('Wait...');
      setTimeout(() => {
        console.log('Start!');
        setLoading(false);
      }, 500);

      return () => {
        unsubscribe1();
        unsubscribe2();
        unsubscribe3();
      };
    }
  }, [isLogin]);

  return isError ? (
    <ErrorModal
      message1="スマートチェックインを起動できません。"
      message2="スタッフにお声かけください。"
      open={true}
    ></ErrorModal>
  ) : !isLoading ? (
    <React.StrictMode>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Top />} />\
          <Route path="/top1" element={<Top mode={TopMode.top} />} />
          <Route path="/top2" element={<Top mode={TopMode.buy} />} />
          <Route path="/top3" element={<Top mode={TopMode.adjustment} />} />
          <Route path="/interview" element={<Interview />} />
          <Route
            path="/standby/wait-measurement"
            element={<WaitMeasurement />}
          />
          <Route path="/standby/wait-interview" element={<WaitInterview />} />
          <Route path="/standby/wait-adjustment" element={<WaitAdjustment />} />
          <Route
            path="/standby/call-measurement"
            element={<CallMeasurement />}
          />
          <Route path="/standby/call-interview" element={<CallInterview />} />
          <Route path="/standby/call-adjustment" element={<CallAdjustment />} />
          <Route path="/standby/wait-acceptance" element={<WaitAcceptance />} />
          <Route path="/standby/call-acceptance" element={<CallAcceptance />} />
          {/*
           * debug routes
           */}
          <Route
            path="/standby/wait-measurement/last"
            element={
              <WaitMeasurement
                waitMeasurementQueueStatus={{ number: 1, time: '10:48' }}
              />
            }
          />
          <Route path="/standby/wait-datasync" element={<WaitDataSync />} />
          {/*
           * debug routes end
           */}
          <Route
            path="/standby/processing-completed"
            element={<Top mode={TopMode.completed} />}
          />
          <Route path="/error" element={<Error />}></Route>
        </Routes>
      </BrowserRouter>
    </React.StrictMode>
  ) : (
    <>
      <Box
        sx={{
          mt: '-7px',
          '& svg': {
            display: 'block',
            width: '100%',
            height: 'auto',
          },
        }}
      >
        <BGImage />
      </Box>
      <Backdrop
        sx={{
          background: 'rgba(0, 0, 0, 0)',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={true}
      >
        <Loading />
      </Backdrop>
    </>
  );
};

export default App;
