declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}

type InitializeEvent = {
  event: 'initialize';
};

type Notification_openEvent = {
  event: 'notification_open';
};

type LiffCheckIn = {
  event: 'liff_check_in';
};

type LiffCancel = {
  event: 'liff_cancel';
};

export type CustomEvent =
  | InitializeEvent
  | Notification_openEvent
  | LiffCheckIn
  | LiffCancel;

export const pushDataLayer = (data: CustomEvent): void => {
  setTimeout(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(data);
  }, 0);
};
