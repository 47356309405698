import { Box, Typography } from '@mui/material';
import ShopHeader from 'components/ShopHeader';
import WaitNumber from 'components/WaitNumber';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { dateFormat } from '../../utils/datetime';

const WaitDataSync = () => {
  const storeInfo = useSelector((state: RootState) => state.store);
  const extStatusInfo = useSelector(
    (state: RootState) => state.receipt.ext_status,
  );
  const receiptList = useSelector(
    (state: RootState) => state.receipt.receipt_status,
  );
  const callCode = useSelector((state: RootState) => state.receipt.call_code);
  const callType = callCode && callCode.charAt(0).toLowerCase();
  const navigate = useNavigate();

  useEffect(() => {
    console.log('データ連携待ち...');
  }, []);

  useEffect(() => {
    const ext_active = Object.keys(extStatusInfo)
      .map(function (key) {
        return extStatusInfo[key];
      })
      .filter((v) => {
        const index = receiptList.findIndex(
          (item) => item.receipt_no === v.receipt_no,
        );
        return (
          v.be_updated_at &&
          dateFormat(new Date(v.be_updated_at), 'YYYY/MM/DD') ===
            dateFormat(new Date(), 'YYYY/MM/DD') &&
          (v.ext_state === '100_prep' ||
            v.ext_state === '200_prep' ||
            v.ext_state === '500_adj1_prep') &&
          index < 0
        );
      });
    if (!ext_active || ext_active.length === 0) {
      navigate('/');
    }
  }, [receiptList, extStatusInfo]);

  const bgColor = callType === 'f' ? '#CDE2F2' : '#C7E4D6';

  return (
    <Box sx={{ backgroundColor: bgColor, pt: 6, pb: 16, minHeight: '100vh' }}>
      <Box sx={{ pl: 4, pr: 4 }}>
        <Box>
          <ShopHeader shopName={storeInfo.store_name || ''} />
        </Box>
        <Box sx={{ mt: 4 }}>
          <Box
            sx={{
              backgroundColor: '#ffffff',
              borderRadius: '15px',
              paddingTop: 4,
              pl: 4,
              pr: 4,
              pb: 4,
            }}
          >
            <Box sx={{ mt: 2 }}>
              <Typography
                textAlign={'center'}
                sx={{
                  fontSize: 24,
                  lineHeight: 35 / 24,
                  fontWeight: 700,
                  color: '#000000',
                }}
              >
                受付完了
              </Typography>
            </Box>
            <Box sx={{ mt: 2 }}>
              <Typography
                textAlign={'center'}
                sx={{
                  fontSize: 14,
                  lineHeight: 20 / 14,
                  fontWeight: 500,
                  color: '#000000',
                }}
              >
                待ち情報の詳細が表示されるまで、
                <br />
                しばらくお待ちください。
              </Typography>
            </Box>
            <Box sx={{ mt: 4 }}>
              <WaitNumber
                type={
                  callType === 'a' || callType === 'b' || callType === 'f'
                    ? callType
                    : 'a'
                }
                number={callCode || '-'}
              ></WaitNumber>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default WaitDataSync;
