import React from 'react';
import { createRoot } from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import App from './App';
import theme from './theme';
import { Provider } from 'react-redux';
import { store } from 'store';
import './index.css';
import liff from '@line/liff';
import ErrorModal from 'components/modal/ErrorModal';

const rootElement = document.getElementById('root');
if (rootElement) {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const root = createRoot(rootElement!);

  liff
    .init({
      liffId: process.env.REACT_APP_LIFF_ID || '',
    })
    .then(async () => {
      liff.ready.then(() => {
        if (!liff.isLoggedIn()) {
          liff.login({ redirectUri: window.location.href });
        }
        //        liff.permission.query('profile').then((permissionStatus) => {
        //          if (permissionStatus.state === 'prompt') {
        //            liff.permission
        //              .requestAll()
        //              .then(() => {
        //                root.render(
        //                  <Provider store={store}>
        //                    <ThemeProvider theme={theme}>
        //                      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        //                      <CssBaseline />
        //                      <App />
        //                    </ThemeProvider>
        //                  </Provider>,
        //                );
        //              })
        //              .catch((e) => {
        //                root.render(
        //                  <ErrorModal
        //                    message1="スマートチェックインを起動できません。"
        //                    message2="スタッフにお声かけください。"
        //                    open={true}
        //                  ></ErrorModal>,
        //                );
        //              });
        //          }
        //        });
        root.render(
          <Provider store={store}>
            <ThemeProvider theme={theme}>
              {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
              <CssBaseline />
              <App />
            </ThemeProvider>
          </Provider>,
        );
      });
    })
    .catch((e) => {
      root.render(
        <ErrorModal
          message1="スマートチェックインを起動できません。"
          message2="スタッフにお声かけください。"
          open={true}
        ></ErrorModal>,
      );
    });
}
