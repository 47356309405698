import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const enum SituationType {
  Buy,
  Adjustment,
  Warranty,
  Consultation,
}

export const enum UseCaseType {
  Everyday,
  Myopia,
  Bifocals,
  Consultation,
}
export const enum ContactType {
  Glasses,
  Contact,
  None,
}

export type InterviewState = {
  eyesightTest?: {
    contact: ContactType;
    useCase: UseCaseType;
  };
  age: number;
  number: number;
  name?: string;
  TelephoneNumber?: string;
};

export const enum StateType {
  //  Request, // a b f
  WaitMeasurement, // 測定待ち a
  CallMeasurement, // 測定呼び出し a
  WaitInterview, // 問診待ち b
  CallInterview, // 問診呼び出し b
  WaitAdjustment, // 調整待ち f
  CallAdjustment, // 調整呼び出し f
  WaitAcceptance, // 受け取り待ち f
  CallAcceptance, // 受け取り呼び出し f
  Cancel, // キャンセル a b f
  // 会計呼び出し -
  // 加工完了 -
  Complete, // 完了（購入なし）
  // 完了（購入あり）situation_buy
}
export type WaitMeasurementQueueStatus = {
  number: number;
  time: string;
};
export type WaitInterviewQueueStatus = {
  number: number;
  minutes: number;
};
export type WaitAdjustmentQueueStatus = {
  number: number;
  minutes: number;
};
export type WaitAcceptanceQueueStatus = {
  number: number;
  minutes: number;
};
export type RequestState = {
  situation?: SituationType;
  interview?: InterviewState;
  state?: StateType;
  customerId?: string;
  waitMeasurementQueueStatus?: WaitMeasurementQueueStatus;
  waitInterviewQueueStatus?: WaitInterviewQueueStatus;
  waitAdjustmentQueueStatus?: WaitAdjustmentQueueStatus;
  waitAcceptanceQueueStatus?: WaitAcceptanceQueueStatus;
};

export const initialState: RequestState = {
  waitMeasurementQueueStatus: { number: 2, time: '10:48' },
  waitInterviewQueueStatus: { number: 2, minutes: 15 },
  waitAdjustmentQueueStatus: { number: 2, minutes: 15 },
  waitAcceptanceQueueStatus: { number: 2, minutes: 15 },
};

export const requestSlice = createSlice({
  name: 'request',
  initialState,
  reducers: {
    setSituation: (state, action: PayloadAction<SituationType>) => {
      state.situation = action.payload;
    },
    setInterview: (state, action: PayloadAction<InterviewState>) => {
      state.interview = action.payload;
    },
    setCustomerId: (state, action: PayloadAction<string>) => {
      state.customerId = action.payload;
    },
    setWaitMeasurementQueueStatus: (
      state,
      action: PayloadAction<WaitMeasurementQueueStatus>,
    ) => {
      state.waitMeasurementQueueStatus = action.payload;
    },
    setWaitInterviewQueueStatus: (
      state,
      action: PayloadAction<WaitInterviewQueueStatus>,
    ) => {
      state.waitInterviewQueueStatus = action.payload;
    },
    setWaitAdjustmentQueueStatus: (
      state,
      action: PayloadAction<WaitAdjustmentQueueStatus>,
    ) => {
      state.waitAdjustmentQueueStatus = action.payload;
    },
    setWaitAcceptanceQueueStatus: (
      state,
      action: PayloadAction<WaitAcceptanceQueueStatus>,
    ) => {
      state.waitAcceptanceQueueStatus = action.payload;
    },
  },
});

export const {
  setSituation,
  setInterview,
  setCustomerId,
  setWaitMeasurementQueueStatus,
  setWaitInterviewQueueStatus,
  setWaitAdjustmentQueueStatus,
  setWaitAcceptanceQueueStatus,
} = requestSlice.actions;
export default requestSlice.reducer;
