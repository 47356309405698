import { Box, Button, ButtonProps } from '@mui/material';
import { ReactComponent as Arrow } from 'assets/images/top/top-navigation-button__arrow.svg';
import React from 'react';

type TopNavigationButtonProps = ButtonProps & {
  labelColor: string;
  backgroundColor: string;
  disabledLabelColor?: string;
  disabledBackgroundColor?: string;
  big?: boolean;
};

const TopNavigationButton = ({
  backgroundColor,
  labelColor,
  big,
  ...props
}: TopNavigationButtonProps) => {
  return (
    <Button
      fullWidth
      disableElevation
      {...props}
      sx={{
        backgroundColor: backgroundColor,
        color: labelColor,
        borderRadius: '9999px',
        fontSize: !big ? 16 : 28,
        lineHeight: 24 / 16,
        pr: '40px',
        pt: !big ? '22px' : '26px',
        pb: !big ? '19px' : '21px',
        fontWeight: 700,
        position: 'relative',
        '&:hover': {
          backgroundColor: backgroundColor,
        },
        '&.Mui-disabled': {
          backgroundColor: backgroundColor,
          color: labelColor,
        },
      }}
    >
      {props.children}
      <Box
        sx={{
          position: 'absolute',
          width: !big ? '20px' : '25px',
          height: !big ? '20px' : '25px',
          top: 0,
          bottom: 0,
          right: !big ? '23px' : '40px',
          margin: 'auto',
          '& svg': {
            display: 'block',
            width: '100%',
            height: 'auto',
          },
        }}
      >
        <Arrow />
      </Box>
    </Button>
  );
};
export default TopNavigationButton;
