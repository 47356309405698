import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { dateFormat } from '../utils/datetime';

export interface ReceiptInfo {
  max_priority: ReceiptData | undefined;
  receipt_status: ReceiptData[];
  ext_status: ExtStatus;
  user_receipt: UserReceipt;
  call_code: string | undefined;
}

export interface UserReceiptData {
  receipt_no: string;
  line_user_id?: string;
  store_code?: string;
  be_updated_at?: string;
}

export interface UserReceipt {
  [key: string]: UserReceiptData;
}

export interface ReceiptData {
  receipt_no: string;
  line_user_id?: string;
  ticket_number?: string;
  ticket_time_received?: string;
  ticket_waiting_time?: number;
  ticket_status?: string;
  ticket_adjust_kbn?: string;
  ticket_estimated_time?: string;
  threshold_wait_order?: number;
  b_threshold_wait_order?: number;
  f_threshold_wait_order?: number;
  store_code?: string;
  store_a_waiting_people?: number;
  store_b_waiting_people?: number;
  store_c_waiting_people?: number;
  store_f_waiting_people?: number;
  store_waiting_time?: number;
  store_take_lens_time?: number;
  store_active_lines?: number;
  store_reception_status?: string;
  store_process_status?: string;
  store_ticket_count?: number;
  be_updated_at?: string;
}

export interface ExtStatusData {
  receipt_no: string;
  line_user_id?: string;
  ext_state?: string;
  be_updated_at?: string;
}

export interface ExtStatus {
  [key: string]: ExtStatusData;
}

const initialState: ReceiptInfo = {
  max_priority: undefined,
  receipt_status: [],
  ext_status: {},
  user_receipt: {},
  call_code: undefined,
};

const receiptSlice = createSlice({
  name: 'receipt',
  initialState,
  reducers: {
    clearReceipt: (state: ReceiptInfo) => {
      state.max_priority = undefined;
      state.receipt_status = [];
      state.ext_status = {};
      state.user_receipt = {};
      //      state.call_code = undefined;
    },

    // 受付票
    pushReceipt: (state: ReceiptInfo, action: PayloadAction<ReceiptData>) => {
      state.receipt_status.unshift(action.payload);
      state.max_priority = getMaxPrioritySelector(state);
    },
    updateReceipt: (state: ReceiptInfo, action: PayloadAction<ReceiptData>) => {
      const index = state.receipt_status.findIndex(
        (item) => item.receipt_no === action.payload.receipt_no,
      );
      state.receipt_status[index] = action.payload;
      state.max_priority = getMaxPrioritySelector(state);
    },
    removeReceipt: (state: ReceiptInfo, action: PayloadAction<ReceiptData>) => {
      state.receipt_status = state.receipt_status.filter(
        (item) => item.receipt_no !== action.payload.receipt_no,
      );
      state.max_priority = getMaxPrioritySelector(state);
    },

    // 拡張ステータス
    pushExtStatus: (
      state: ReceiptInfo,
      action: PayloadAction<ExtStatusData>,
    ) => {
      state.ext_status[action.payload.receipt_no] = action.payload;
      state.max_priority = getMaxPrioritySelector(state);
    },
    updateExtStatus: (
      state: ReceiptInfo,
      action: PayloadAction<ExtStatusData>,
    ) => {
      state.ext_status[action.payload.receipt_no] = action.payload;
      state.max_priority = getMaxPrioritySelector(state);
    },
    removeExtStatus: (
      state: ReceiptInfo,
      action: PayloadAction<ExtStatusData>,
    ) => {
      delete state.ext_status[action.payload.receipt_no];
      state.max_priority = getMaxPrioritySelector(state);
    },

    // ユーザと受付票の紐付け
    pushUserReceipt: (
      state: ReceiptInfo,
      action: PayloadAction<UserReceiptData>,
    ) => {
      state.user_receipt[action.payload.receipt_no] = action.payload;
    },
    updateUserReceipt: (
      state: ReceiptInfo,
      action: PayloadAction<UserReceiptData>,
    ) => {
      state.user_receipt[action.payload.receipt_no] = action.payload;
    },
    removeUserReceipt: (
      state: ReceiptInfo,
      action: PayloadAction<UserReceiptData>,
    ) => {
      delete state.user_receipt[action.payload.receipt_no];
    },

    // 受付番号
    setCallCode: (state: ReceiptInfo, action: PayloadAction<string>) => {
      state.call_code = action.payload;
    },
  },
});

const getMaxPrioritySelector = (state: ReceiptInfo) => {
  // 受付表なし
  if (!state.receipt_status) {
    return undefined;
  }

  // 当日のアクティブな受付票（受付番号順）
  const active = state.receipt_status
    .filter(
      (v) =>
        v.be_updated_at &&
        dateFormat(new Date(v.be_updated_at), 'YYYY/MM/DD') ===
          dateFormat(new Date(), 'YYYY/MM/DD') &&
        //        v.ticket_status !== '600' &&
        '100:110:120:190:191:192:200:210:220:290:291:292:500:510:520:590:591:592'.indexOf(
          v.ticket_status || 'xxx',
        ) >= 0 &&
        state.ext_status &&
        state.ext_status[v.receipt_no]?.ext_state !== 'cancel',
    )
    .sort(function (first, second) {
      if (first.receipt_no > second.receipt_no) {
        return 1;
      } else if (first.receipt_no < second.receipt_no) {
        return -1;
      } else {
        return 0;
      }
    });
  console.log('active');
  console.log(active);

  // アクティブな受付表なし
  if (!active) {
    return undefined;
  }

  // 第一優先の受付票（時間・受付番号順）
  const first = active
    .filter(
      (v) =>
        (v.ticket_status === '500' ||
          v.ticket_status === '510' ||
          v.ticket_status === '590' ||
          v.ticket_status === '591') &&
        v.ticket_adjust_kbn === '0',
    )
    .sort(function (first, second) {
      if (
        (first.ticket_time_received || '9999-99-99T99:99:99.999Z') >
        (second.ticket_time_received || '9999-99-99T99:99:99.999Z')
      ) {
        return 1;
      } else if (
        (first.ticket_time_received || '9999-99-99T99:99:99.999Z') <
        (second.ticket_time_received || '9999-99-99T99:99:99.999Z')
      ) {
        return -1;
      } else {
        return 0;
      }
    });
  console.log('first');
  console.log(first);

  // 第二優先の受付票（時間・受付番号順）
  const second =
    active &&
    active
      .filter((v) => v.ticket_status === '520' && v.ticket_adjust_kbn === '0')
      .sort(function (first, second) {
        if (
          (first.ticket_time_received || '9999-99-99T99:99:99.999Z') >
          (second.ticket_time_received || '9999-99-99T99:99:99.999Z')
        ) {
          return 1;
        } else if (
          (first.ticket_time_received || '9999-99-99T99:99:99.999Z') <
          (second.ticket_time_received || '9999-99-99T99:99:99.999Z')
        ) {
          return -1;
        } else {
          return 0;
        }
      });
  console.log('second');
  console.log(second);

  // 第一優先の受付表なし
  if (first && first.length > 0) {
    return first[0];
  }

  // 第二優先の受付表なし
  if (second && second.length > 0) {
    return second[0];
  }

  return active[0];
};

export const {
  clearReceipt,
  pushUserReceipt,
  updateUserReceipt,
  removeUserReceipt,
  pushReceipt,
  updateReceipt,
  removeReceipt,
  pushExtStatus,
  updateExtStatus,
  removeExtStatus,
  setCallCode,
} = receiptSlice.actions;

export default receiptSlice.reducer;
