import { Box, Typography } from '@mui/material';
import ShopHeader from 'components/ShopHeader';
import WaitNumber from 'components/WaitNumber';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as BGImage } from 'assets/images/standby/call-image--2.svg';

const CallAdjustment = () => {
  const storeInfo = useSelector((state: RootState) => state.store);
  const receiptInfo = useSelector(
    (state: RootState) => state.receipt.max_priority,
  );
  const extStatusInfo = useSelector(
    (state: RootState) => state.receipt.ext_status,
  );
  const navigate = useNavigate();

  useEffect(() => {
    console.log('調整呼出...');
  }, []);

  useEffect(() => {
    const ext_status =
      receiptInfo?.receipt_no &&
      extStatusInfo[receiptInfo.receipt_no]?.ext_state;

    if (
      !(
        (receiptInfo?.ticket_status === '510' ||
          receiptInfo?.ticket_status === '520' ||
          receiptInfo?.ticket_status === '590' ||
          receiptInfo?.ticket_status === '591' ||
          receiptInfo?.ticket_status === '592' ||
          (receiptInfo?.ticket_status === '500' &&
            ext_status === '500_adj1_ext')) &&
        receiptInfo?.ticket_adjust_kbn === '1'
      )
    ) {
      navigate('/');
    }
  }, [receiptInfo]);

  return (
    <Box sx={{ backgroundColor: '#c9eef6', minHeight: '100vh' }}>
      <Box
        sx={{
          backgroundColor: '#cde2f2',
          pt: 6,
          background:
            'linear-gradient(180deg, rgba(205,226,242,1) 0%, rgba(205,226,242,1) 98%, rgba(201,238,246,1) 99%, rgba(201,238,246,1) 100%)',
        }}
      >
        <Box sx={{ pl: 4, pr: 4 }}>
          <Box>
            <ShopHeader shopName={storeInfo.store_name || ''} />
          </Box>
          <Box sx={{ mt: 4 }}>
            <Box
              sx={{
                backgroundColor: '#ffffff',
                borderRadius: '15px',
                paddingTop: 4,
                pl: 4,
                pr: 4,
                pb: 4,
              }}
            >
              <Box>
                <Typography
                  textAlign={'center'}
                  sx={{
                    fontSize: 24,
                    lineHeight: 35 / 24,
                    fontWeight: 500,
                    color: '#000000',
                  }}
                >
                  お待たせしました！
                </Typography>
              </Box>
              <Box sx={{ mt: '3px' }}>
                <Typography
                  textAlign={'center'}
                  sx={{
                    fontSize: 14,
                    lineHeight: 20 / 14,
                    fontWeight: 500,
                    color: '#000000',
                  }}
                >
                  調整の順番となりました。
                  <br />
                  呼出スタッフにお声がけください。
                </Typography>
              </Box>
              <Box sx={{ mt: 2 }}>
                <WaitNumber
                  type={'f'}
                  number={
                    receiptInfo?.ticket_number !== undefined
                      ? receiptInfo.ticket_number
                      : '-'
                  }
                ></WaitNumber>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            mt: 8,
            '& > svg': {
              display: 'block',
              width: '100%',
              height: 'auto',
            },
          }}
        >
          <BGImage></BGImage>
        </Box>
      </Box>
    </Box>
  );
};
export default CallAdjustment;
