import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

type QueueStatusProps = {
  label: string;
  mainValue: number | string;
  mainValueUnit?: string;
  mainValuePrefix?: string;
  subValue?: number | string;
  subValueUnit?: string;
  subValuePrefix?: string;
};
const QueueStatus = (props: QueueStatusProps) => {
  return (
    <Box
      sx={{
        borderRadius: '15px',
        pt: '17px',
        pl: 4,
        pr: 4,
        pb: '5px',
        bgcolor: '#ffffff',
        position: 'relative',
      }}
    >
      <Typography
        sx={{
          fontSize: 16,
          fontWeight: 500,
          color: '#000000',
          lineHeight: 24 / 16,
        }}
      >
        {props.label}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {props.subValue && (
          <Box
            sx={{
              display: 'flex',
              mt: '19px',
            }}
          >
            {props.subValuePrefix && (
              <Typography
                sx={{
                  fontSize: 16,
                  fontWeight: 500,
                  color: '#000000',
                  lineHeight: 20 / 16,
                  mt: '3px',
                }}
              >
                {props.subValuePrefix}
              </Typography>
            )}
            <Typography
              sx={{
                fontSize: '24px',
                fontWeight: 500,
                color: '#000000',
                lineHeight: 24 / 24,
                ml: '3px',
              }}
            >
              {props.subValue}
            </Typography>
            {props.subValueUnit && (
              <Typography
                sx={{
                  fontSize: 16,
                  fontWeight: 500,
                  color: '#000000',
                  lineHeight: 20 / 16,
                  mt: '3px',
                  ml: '3px',
                }}
              >
                {props.subValueUnit}
              </Typography>
            )}
          </Box>
        )}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            textAlign: 'right',
            flexGrow: 1,
          }}
        >
          {props.mainValuePrefix && (
            <Typography
              sx={{
                fontSize: 16,
                fontWeight: 500,
                color: '#000000',
                lineHeight: 48 / 16,
                mt: '7px',
              }}
            >
              {props.mainValuePrefix}
            </Typography>
          )}
          <Typography
            sx={{
              fontSize: '48px',
              fontWeight: 500,
              color: '#000000',
              lineHeight: 48 / 48,
              ml: '3px',
            }}
          >
            {props.mainValue}
          </Typography>
          {props.mainValueUnit && (
            <Typography
              sx={{
                fontSize: '16px',
                fontWeight: 500,
                color: '#000000',
                lineHeight: 48 / 16,
                mt: '7px',
                ml: '3px',
              }}
            >
              {props.mainValueUnit}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};
export default QueueStatus;
