import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { dateFormat } from '../utils/datetime';
import shopData from './shop.json';

export interface StoreInfo {
  store_code: string | undefined;
  store_name: string | undefined;
  required_name: boolean | undefined;
  required_tel: boolean | undefined;
  checkin_start_at: string;
  checkin_end_at: string;
  data?: StoreData;
}

export interface StoreData {
  store_a_waiting_people?: number;
  store_b_waiting_people?: number;
  store_c_waiting_people?: number;
  store_f_waiting_people?: number;
  store_waiting_time?: number;
  store_take_lens_time?: number;
  store_active_lines?: number;
  store_reception_status?: string;
  store_process_status?: string;
  store_ticket_count?: number;
  be_updated_at?: string;
}

const initialState: StoreInfo = {
  store_code: undefined,
  store_name: undefined,
  required_name: undefined,
  required_tel: undefined,
  checkin_start_at: '10:00',
  checkin_end_at: '22:00',
};

interface StoreMaster {
  [key: string]: StoreMasterData;
}

interface StoreMasterData {
  name: string;
  required_name: boolean;
  required_tel: boolean;
  checkin_start_at: string;
  checkin_end_at: string;
}

const storeMaster: StoreMaster = shopData;
console.log(dateFormat(new Date(), 'YYYY-MM-DDThh:mm:ss+0900'), storeMaster);

const storeSlice = createSlice({
  name: 'store',
  initialState,
  reducers: {
    setStoreCode: (
      state: StoreInfo,
      action: PayloadAction<string | undefined>,
    ) => {
      state.store_code = action.payload;
      state.store_name = state.store_code
        ? storeMaster[state.store_code].name
        : undefined;
      state.required_name = state.store_code
        ? storeMaster[state.store_code].required_name
        : true;
      state.required_tel = state.store_code
        ? storeMaster[state.store_code].required_tel
        : true;
      state.checkin_start_at = state.store_code
        ? storeMaster[state.store_code].checkin_start_at
        : '10:00';
      state.checkin_end_at = state.store_code
        ? storeMaster[state.store_code].checkin_end_at
        : '22:00';
    },
    setStoreInfo: (state: StoreInfo, action: PayloadAction<StoreInfo>) => {
      state.store_code = action.payload.store_code;
      state.store_name = action.payload.store_name;
    },
    setStoreData: (
      state: StoreInfo,
      action: PayloadAction<StoreData | undefined>,
    ) => {
      state.data = action.payload;
    },
    clearStoreInfo: (state: StoreInfo) => {
      state.store_code = undefined;
      state.store_name = undefined;
      state.data = undefined;
    },
  },
});

export const { setStoreCode, setStoreInfo, setStoreData } = storeSlice.actions;

export default storeSlice.reducer;
