import { TextField, TextFieldProps } from '@mui/material';
import React from 'react';
import { Control, Path, RegisterOptions, useController } from 'react-hook-form';

type TextBoxProps = TextFieldProps & {
  name: Path<any>;
  control: Control<any>;
  rules?: RegisterOptions;
};

const TextBox = (props: TextBoxProps) => {
  const { name, control, rules, children, ...textFieldProps } = props;
  const {
    field: { ref, ...rest },
  } = useController({ name, control, rules });
  return (
    <TextField
      inputRef={ref}
      {...rest}
      {...textFieldProps}
      sx={{
        width: '100%',
        display: 'flex',
        fontSize: 16,
        lineHeight: 20 / 16,
        '& .MuiOutlinedInput-input': {
          width: '100%',
          display: 'block',
          pt: 4,
          pb: '17px',
        },
        '& .MuiOutlinedInput-root': {
          borderRadius: '10px',
          background: '#fff',
        },
        '& .MuiOutlinedInput-notchedOutline': {
          border: 0,
        },
      }}
    >
      {children}
    </TextField>
  );
};
export default TextBox;
