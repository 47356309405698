import { Box, Typography } from '@mui/material';
import Modal, { ModalProps } from 'components/Modal';
import NavigationButton from 'components/NavigationButton';
import React from 'react';
// import { ReactComponent as Icon } from 'assets/images/modal/line-notification-modal__icon.svg';
import { ReactComponent as Image } from 'assets/images/modal/line-notification-modal__image.svg';

export type LineNotificationModalProps = Omit<ModalProps, 'children'>;
export default function LineNotificationModal(
  props: LineNotificationModalProps,
) {
  return (
    <Modal {...props}>
      <Box sx={{ position: 'relative', pt: 10, pb: 10 }}>
        {/* <Box
          sx={{
            position: 'absolute',
            right: '15px',
            top: '15px',
            width: '32px',
            height: '32px',
            '> svg': {
              display: 'block',
              width: '100%',
              height: 'auto',
            },
          }}
        >
          <Icon></Icon>
        </Box> */}
        <Box>
          <Typography
            textAlign={'center'}
            sx={{
              fontSize: 24,
              lineHeight: 35 / 24,
              color: '#000000',
              fontWeight: 500,
            }}
          >
            LINEの通知をONに。
          </Typography>
        </Box>
        <Box sx={{ mt: 4 }}>
          <Typography
            textAlign={'center'}
            sx={{ fontSize: 14, lineHeight: 20 / 14, color: '#000000' }}
          >
            順番が来たらお知らせしますので、<br></br>
            LINEの通知をONにしておいてください。
          </Typography>
        </Box>
        <Box
          sx={{
            width: '181px',
            margin: 'auto',
            mt: 4,
            '> svg': {
              display: 'block',
              width: '100%',
              height: 'auto',
            },
          }}
        >
          <Image></Image>
        </Box>
        <Box
          sx={{
            margin: 'auto',
            mt: 6,
            width: '220px',
          }}
        >
          <NavigationButton
            backgroundColor="#E0E0E0"
            labelColor="#000000"
            onClick={() => {
              props.onClose && props.onClose({}, 'backdropClick');
            }}
          >
            閉じる
          </NavigationButton>
        </Box>
      </Box>
    </Modal>
  );
}
