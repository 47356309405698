import {
  Box,
  Checkbox,
  CheckboxProps as MUICheckboxProps,
  FormControlLabel,
} from '@mui/material';
import React, { ReactElement } from 'react';
import { ReactComponent as Icon } from 'assets/images/interview/checkbox-icon.svg';
import { ReactComponent as CheckedIcon } from 'assets/images/interview/checkbox-icon--checked.svg';
export type CheckboxProps = MUICheckboxProps & {
  label: ReactElement;
};

export default function CheckBox(props: CheckboxProps) {
  const { label, ...otherProps } = props;
  return (
    <Box>
      <FormControlLabel
        control={
          <Checkbox
            {...otherProps}
            icon={
              <Box
                sx={{
                  width: '32px',
                  '& > svg': {
                    display: 'block',
                    width: '100%',
                    height: 'auto',
                  },
                }}
              >
                <Icon />
              </Box>
            }
            checkedIcon={
              <Box
                sx={{
                  width: '32px',
                  '& > svg': {
                    display: 'block',
                    width: '100%',
                    height: 'auto',
                  },
                }}
              >
                <CheckedIcon />
              </Box>
            }
          />
        }
        label={<Box>{label}</Box>}
      />
    </Box>
  );
}
