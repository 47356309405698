import { Box, Typography } from '@mui/material';
import Modal, { ModalProps } from 'components/Modal';
import NavigationButton from 'components/NavigationButton';
import ReceptionNumber from 'components/ReceptionNumber';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

export type CompleteModalProps = Omit<ModalProps, 'children'>;
export default function CompleteModal(props: CompleteModalProps) {
  const userInfo = useSelector((state: RootState) => state.user);
  return (
    <Modal {...props}>
      <Box sx={{ pt: 10, pb: 10 }}>
        <Box>
          <Typography
            textAlign={'center'}
            sx={{ fontSize: 24, lineHeight: 35 / 24, color: '#000' }}
          >
            商品が出来上がりました！
          </Typography>
        </Box>
        <Box
          sx={{
            mt: 4,
            pl: 4,
            pr: 4,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Typography
            textAlign={'center'}
            sx={{
              fontSize: 14,
              lineHeight: 20 / 14,
              fontWeight: 500,
              color: '#000000',
            }}
          >
            店頭スタッフにこの画面をお見せいただくかPICK UP
            LOCKERにQRをかざしてください。
          </Typography>
        </Box>
        <Box
          sx={{
            width: '180px',
            m: 'auto',
            mt: 2,
            img: {
              display: 'block',
              width: '100%',
              height: 'auto',
              aspectRatio: 1,
            },
          }}
        >
          <img
            src={`https://chart.apis.google.com/chart?cht=qr&chs=150x150&chl=${userInfo.receipt_no}`}
            alt=""
          />
        </Box>
        <Box sx={{ mt: 2, pl: 4, pr: 4 }}>
          <ReceptionNumber
            number={userInfo.receipt_no || '-'}
          ></ReceptionNumber>
        </Box>
        <Box sx={{ width: '220px', m: 'auto', mt: 6 }}>
          <NavigationButton
            labelColor={'#000000'}
            backgroundColor={'#E0E0E0'}
            onClick={() => {
              props.onClose && props.onClose({}, 'backdropClick');
            }}
          >
            閉じる
          </NavigationButton>
        </Box>
      </Box>
    </Modal>
  );
}
