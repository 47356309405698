import { Box, styled } from '@mui/material';
import React from 'react';

export type ProgressStatusProps = {
  items: Array<string>;
  currentIndex: number;
};
const StyledList = styled('ol')({
  display: 'flex',
  justifyContent: 'center',
  listStyle: 'none',
  margin: 0,
  padding: 0,
  '> li + li': {
    marginLeft: '20px',
  },
  '> li + li::before': {
    content: '""',
    display: 'block',
    height: '3px',
    backgroundColor: '#E0E0E0',
    position: 'absolute',
    left: '-21px',
    width: '22px',
    zIndex: 1,
    top: '24px',
  },
});
const StyledItem = styled('li')({
  width: '50px',
  height: '50px',
  borderRadius: '9999px',
  backgroundColor: '#E0E0E0',
  color: '#000000',
  textAlign: 'center',
  fontSize: 12,
  lineHeight: 17 / 12,
  position: 'relative',
  zIndex: 2,
  paddingTop: '18px',
});
export default function ProgressStatus(props: ProgressStatusProps) {
  return (
    <Box>
      <StyledList>
        {props.items.map((item, index) => {
          return (
            <StyledItem
              key={index}
              sx={{
                backgroundColor:
                  index === props.currentIndex ? '#53B385' : '#E0E0E0',
                color: index === props.currentIndex ? '#ffffff' : '#000000',
              }}
            >
              {item}
            </StyledItem>
          );
        })}
      </StyledList>
    </Box>
  );
}
