import { Select, SelectProps } from '@mui/material';
import React from 'react';

const SelectBox = (props: SelectProps) => {
  return (
    <Select
      sx={{
        width: '100%',
        borderRadius: 9999,
        background: '#ffffff',
        fontSize: 14,
        lineHeight: 20 / 14,
        textAlign: 'right',
        '.MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
        '.MuiSelect-select': {
          pt: 4,
          pb: 4,
          pr: 8,
        },
      }}
      {...props}
    >
      {props.children}
    </Select>
  );
};
export default SelectBox;
