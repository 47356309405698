import { Box, Typography } from '@mui/material';
import React from 'react';

export type WaitNumberProps = {
  type: 'a' | 'b' | 'f';
  number: string;
};
export default function WaitNumber(props: WaitNumberProps) {
  return (
    <Box
      sx={{
        borderRadius: '20px',
        backgroundColor: props.type === 'f' ? '#CDE2F2' : '#C7E4D6',
        pl: 4,
        pr: 4,
        display: 'flex',
      }}
    >
      <Box
        sx={{
          pt: '28px',
          pb: '28px',
          flexGrow: 1,
        }}
      >
        <Typography
          sx={{
            fontSize: 16,
            lineHeight: 24 / 16,
            fontWeight: 500,
            color: '#000',
          }}
        >
          受付番号
        </Typography>
      </Box>
      <Box
        sx={{
          pt: '24px',
        }}
      >
        <Typography
          sx={{
            fontSize: 38,
            lineHeight: 38 / 38,
            fontWeight: 500,
            color: '#000',
          }}
        >
          {props.number}
        </Typography>
      </Box>
    </Box>
  );
}
