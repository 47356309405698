import { Button, ButtonProps } from '@mui/material';
import React from 'react';

type NavigationButtonProps = ButtonProps & {
  labelColor: string;
  backgroundColor: string;
  disabledLabelColor?: string;
  disabledBackgroundColor?: string;
};

const NavigationButton = ({
  backgroundColor,
  labelColor,
  disabledLabelColor,
  disabledBackgroundColor,
  ...props
}: NavigationButtonProps) => {
  return (
    <Button
      fullWidth
      disableElevation
      {...props}
      sx={{
        backgroundColor: backgroundColor,
        color: labelColor,
        borderRadius: '9999px',
        fontSize: '18px',
        lineHeight: 26 / 18,
        pt: '23px',
        pb: '21px',
        '&:hover': {
          backgroundColor: backgroundColor,
        },
        '&.Mui-disabled': {
          backgroundColor: disabledBackgroundColor || backgroundColor,
          color: disabledLabelColor || labelColor,
        },
      }}
    >
      {props.children}
    </Button>
  );
};
export default NavigationButton;
