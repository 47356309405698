import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

type TopWaitingTimeForFinishingProps = {
  waitingTime: number | string;
  closed?: boolean;
};
const TopWaitingTimeForFinishing = (props: TopWaitingTimeForFinishingProps) => {
  return (
    <Box>
      <Typography
        sx={{
          fontSize: 16,
          fontWeight: 700,
          color: '#000000',
          lineHeight: 24 / 16,
        }}
      >
        会計から
        <br />
        お仕上げまで
      </Typography>
      <Box
        sx={{
          textAlign: 'center',
          mt: '10px',
        }}
      >
        {props.closed && (
          <Typography
            fontSize={18}
            lineHeight={26 / 18}
            fontWeight={700}
            color={'#DA0024'}
          >
            明日以降と
            <br />
            なります
          </Typography>
        )}
        {!props.closed && (
          <>
            <Typography
              display={'inline'}
              fontSize={50}
              lineHeight={1}
              fontWeight={500}
              color={'#000'}
            >
              {props.waitingTime}
            </Typography>
            <Typography
              display={'inline-block'}
              ml={2}
              fontSize={18}
              lineHeight={26 / 18}
              fontWeight={500}
              color={'#000'}
            >
              分
            </Typography>
          </>
        )}
      </Box>
    </Box>
  );
};
export default TopWaitingTimeForFinishing;
