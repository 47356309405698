import { configureStore } from '@reduxjs/toolkit';
import {
  useSelector as rawUseSelector,
  TypedUseSelectorHook,
} from 'react-redux';
import { save, load } from 'redux-localstorage-simple';
import requestReducer from './requestSlice';
import storeReducer from './storeSlice';
import userReducer from './userSlice';
import receiptReducer from './receiptSlice';
import errorReducer from './errorSlice';
import { dateFormat } from '../utils/datetime';

export const store = configureStore({
  reducer: {
    request: requestReducer,
    store: storeReducer,
    user: userReducer,
    receipt: receiptReducer,
    error: errorReducer,
  },
  preloadedState: load(),
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(save()),
});

store.subscribe(() => {
  console.log(
    dateFormat(new Date(), 'YYYY-MM-DDThh:mm:ss+0900'),
    store.getState(),
  );
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useSelector: TypedUseSelectorHook<RootState> = rawUseSelector;
