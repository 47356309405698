import { Box, Typography } from '@mui/material';
import Modal, { ModalProps } from 'components/Modal';
import NavigationButton from 'components/NavigationButton';
import React from 'react';

export type ErrorModalProps = Omit<ModalProps, 'children'>;
export type ErrorModalPropsEx = ErrorModalProps & {
  title?: string;
  message1?: string;
  message2?: string;
};

export default function ErrorModal(props: ErrorModalPropsEx) {
  const closeButtonHandle = () => {
    props.onClose && props.onClose({}, 'backdropClick');
  };
  return (
    <Modal {...props}>
      <Box sx={{ position: 'relative', pt: 11, pb: 10 }}>
        {props.title && (
          <Box>
            <Typography
              textAlign={'center'}
              sx={{
                fontSize: 30,
                fontWeight: 700,
                lineHeight: 30 / 30,
              }}
            >
              {props.title}
            </Typography>
          </Box>
        )}
        <Box sx={{ mt: 4 }}>
          <Typography
            textAlign={'center'}
            sx={{
              fontSize: 14,
              fontWeight: 500,
              lineHeight: 20 / 14,
            }}
          >
            {props.message1}
            <br />
            {props.message2}
          </Typography>
        </Box>
        <Box
          sx={{
            width: '220px',
            m: 'auto',
            mt: 6,
          }}
        >
          {props.onClose && (
            <NavigationButton
              labelColor={'#000000'}
              backgroundColor={'#E0E0E0'}
              onClick={closeButtonHandle}
            >
              閉じる
            </NavigationButton>
          )}
        </Box>
      </Box>
    </Modal>
  );
}
