import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

type TopWaitingTimeForEyeTestProps = {
  waitingTime: number | string;
  waitingCount: number | string;
  closed?: boolean;
};
const TopWaitingTimeForEyeTest = (props: TopWaitingTimeForEyeTestProps) => {
  return (
    <Box sx={{}}>
      <Typography
        sx={{
          fontSize: 16,
          fontWeight: 700,
          color: '#000000',
          lineHeight: 24 / 16,
        }}
      >
        視力測定まで
      </Typography>
      {props.closed && (
        <Box
          sx={{
            textAlign: 'center',
            mt: '10px',
          }}
        >
          <Typography
            fontSize={18}
            lineHeight={26 / 18}
            fontWeight={700}
            color={'#DA0024'}
          >
            本日の測定
            <br />
            受付は終了
            <br />
            しました
          </Typography>
        </Box>
      )}
      {!props.closed && (
        <>
          <Box
            sx={{
              textAlign: 'center',
              mt: '10px',
            }}
          >
            <Typography
              display={'inline'}
              fontSize={50}
              lineHeight={1}
              color={'#000000'}
              fontWeight={500}
            >
              {props.waitingTime}
            </Typography>
            <Typography
              display={'inline-block'}
              ml={2}
              fontSize={18}
              fontWeight={500}
              lineHeight={26 / 18}
              color={'#000000'}
            >
              分
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              mt: '8px',
            }}
          >
            <Box>
              <Typography
                display={'inline'}
                fontSize={12}
                fontWeight={500}
                lineHeight={17 / 12}
              >
                待ち人数
              </Typography>
            </Box>
            <Box>
              <Typography
                display={'inline'}
                fontSize={20}
                fontWeight={500}
                lineHeight={1}
                sx={{ verticalAlign: -2 }}
              >
                {props.waitingCount}
              </Typography>
              <Typography
                display={'inline-block'}
                ml={1}
                fontSize={12}
                fontWeight={500}
                lineHeight={17 / 12}
              >
                人
              </Typography>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};
export default TopWaitingTimeForEyeTest;
