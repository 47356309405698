import { Box, Typography } from '@mui/material';
import CancelModal from 'components/modal/CancelModal';
import NavigationButton from 'components/NavigationButton';
import QueueStatus from 'components/QueueStatus';
import ShopHeader from 'components/ShopHeader';
import WaitNumber from 'components/WaitNumber';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'store';
import {
  setWaitAdjustmentQueueStatus,
  WaitAdjustmentQueueStatus,
} from 'store/requestSlice';
import { clearAction } from 'store/userSlice';
import { dateFormat } from '../../utils/datetime';
import { httpsCallable } from 'firebase/functions';
import { functions } from 'utils/firebase';
import { ReactComponent as Loading } from 'assets/images/common/LINE_spinner_light.svg';
import Backdrop from '@mui/material/Backdrop/Backdrop';
import { setErrorInfo } from 'store/errorSlice';
import ErrorModal from 'components/modal/ErrorModal';
import { pushDataLayer } from '../../utils/gtm';

type WaitAdjustmentProps = {
  waitAdjustmentQueueStatus?: WaitAdjustmentQueueStatus;
};
const WaitAdjustment = (props: WaitAdjustmentProps) => {
  const userInfo = useSelector((state: RootState) => state.user);
  const storeInfo = useSelector((state: RootState) => state.store);
  const receiptInfo = useSelector(
    (state: RootState) => state.receipt.max_priority,
  );
  const receiptList = useSelector(
    (state: RootState) => state.receipt.receipt_status,
  );
  const extStatusInfo = useSelector(
    (state: RootState) => state.receipt.ext_status,
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  if (props.waitAdjustmentQueueStatus !== undefined) {
    dispatch(setWaitAdjustmentQueueStatus(props.waitAdjustmentQueueStatus));
  }
  const [loadingOpen, setLoadingOpen] = useState(false);
  const [cancelModalOpen, setCancelModalOpen] = useState(
    userInfo.action === 'cancel',
  );
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [errorModalText, setErrorModalText] = useState('');

  const cancelButtonHandle = () => {
    setCancelModalOpen(true);
  };
  const cancelHandle = () => {
    dispatch(clearAction());
    const now = dateFormat(new Date(), 'hh:mm');
    if (
      //      now < (process.env.REACT_APP_STORE_OPEN_TIME || '10:00') ||
      //      now > (process.env.REACT_APP_STORE_CLOSE_TIME || '22:00')
      now < storeInfo.checkin_start_at ||
      now > storeInfo.checkin_end_at
    ) {
      dispatch(
        setErrorInfo({
          body1: 'サービス時間外です。店舗の営業時間内のみご利用いただけます。',
        }),
      );
      navigate('/error');
    } else {
      const active = receiptList
        .filter(
          (v) =>
            v.be_updated_at &&
            dateFormat(new Date(v.be_updated_at), 'YYYY/MM/DD') ===
              dateFormat(new Date(), 'YYYY/MM/DD') &&
            v.ticket_number === receiptInfo?.ticket_number &&
            v.ticket_status === '500' &&
            v.ticket_adjust_kbn === '1',
        )
        .map((v) => {
          return {
            store_code: v.store_code,
            receipt_no: v.receipt_no,
            number: v.ticket_number,
          };
        })
        .sort(function (first, second) {
          if (first.receipt_no > second.receipt_no) {
            return 1;
          } else if (first.receipt_no < second.receipt_no) {
            return -1;
          } else {
            return 0;
          }
        });

      if (active && active.length > 0) {
        try {
          setLoadingOpen(true);
          console.log('lambda-post_receptions_cancel...');
          const postReceptionsCancel = httpsCallable(
            functions,
            'lambda-post_receptions_cancel',
          );
          postReceptionsCancel({
            channel_id: process.env.REACT_APP_LINE_CHANNEL_ID,
            line_id: userInfo.uid,
            receipt_no_list: active,
          }).then((res: any) => {
            console.log(res);
            if (
              res.data.status_code === 200 &&
              res.data.body.return_code === 0
            ) {
              if (res.data.body.data[0].result_code === '0') {
                pushDataLayer({
                  event: 'liff_cancel',
                });
                //        setLoadingOpen(false);
              } else {
                setErrorModalText(
                  res.data.body.data[0].result_message ||
                    '現在スマートチェックインを利用できません。スタッフにお声かけください。',
                );
                setLoadingOpen(false);
                setErrorModalOpen(true);
              }
            } else {
              setErrorModalText(
                res.data.body.return_message ||
                  '現在スマートチェックインを利用できません。スタッフにお声かけください。',
              );
              setLoadingOpen(false);
              setErrorModalOpen(true);
            }
          });
        } catch (e) {
          setErrorModalText(
            '現在スマートチェックインを利用できません。スタッフにお声かけください。',
          );
          setLoadingOpen(false);
          setErrorModalOpen(true);
        }
      }
    }
  };
  const bgColor =
    receiptInfo?.f_threshold_wait_order &&
    receiptInfo.f_threshold_wait_order < 2
      ? '#ffffff'
      : '#ffffff';
  const textColor =
    receiptInfo?.f_threshold_wait_order &&
    receiptInfo.f_threshold_wait_order < 2
      ? '#000000'
      : '#000000';
  const waitText =
    receiptInfo?.f_threshold_wait_order &&
    receiptInfo.f_threshold_wait_order < 2 ? (
      <>
        受付をいたしました。店内でお待ちください。
        <br />
        順番になりましたら通知します。
      </>
    ) : (
      <>
        受付をいたしました。店内でお待ちください。
        <br />
        順番になりましたら通知します。
      </>
    );

  useEffect(() => {
    console.log('調整待ち...');
  }, []);

  useEffect(() => {
    const ext_status =
      receiptInfo?.receipt_no &&
      extStatusInfo[receiptInfo.receipt_no]?.ext_state;

    if (
      !(
        receiptInfo?.ticket_status === '500' &&
        ext_status !== '500_adj1_ext' &&
        ext_status !== 'cancel' &&
        receiptInfo?.ticket_adjust_kbn === '1'
      )
    ) {
      navigate('/');
    }
  }, [receiptInfo]);

  return (
    <Box sx={{ backgroundColor: '#CDE2F2', pt: 6, pb: 16, minHeight: '100vh' }}>
      <Box sx={{ pl: 4, pr: 4 }}>
        <Box>
          <ShopHeader shopName={storeInfo.store_name || ''} />
        </Box>
        <Box sx={{ mt: 4 }}>
          <Box
            sx={{
              backgroundColor: bgColor,
              borderRadius: '15px',
              paddingTop: 4,
              pl: 4,
              pr: 4,
              pb: 4,
            }}
          >
            <Box>
              <Typography
                textAlign={'center'}
                sx={{
                  fontSize: 14,
                  lineHeight: 20 / 14,
                  fontWeight: 500,
                  color: textColor,
                }}
              >
                受付内容
              </Typography>
            </Box>
            <Box sx={{ mt: 2 }}>
              <Typography
                textAlign={'center'}
                sx={{
                  fontSize: 24,
                  lineHeight: 35 / 24,
                  fontWeight: 500,
                  color: textColor,
                }}
              >
                メガネの調整
              </Typography>
            </Box>
            <Box sx={{ mt: '3px' }}>
              <Typography
                textAlign={'center'}
                sx={{
                  fontSize: 14,
                  lineHeight: 20 / 14,
                  fontWeight: 500,
                  color: textColor,
                }}
              >
                {waitText}
              </Typography>
            </Box>
            <Box sx={{ mt: 2 }}>
              <WaitNumber
                type={'f'}
                number={
                  receiptInfo?.ticket_number !== undefined
                    ? receiptInfo.ticket_number
                    : '-'
                }
              ></WaitNumber>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            mt: 2,
          }}
        >
          <QueueStatus
            label="呼び出しまで"
            mainValuePrefix={'あと'}
            mainValue={
              receiptInfo?.f_threshold_wait_order !== undefined
                ? receiptInfo.f_threshold_wait_order + ''
                : '-'
            }
            mainValueUnit={'人'}
          />
        </Box>
      </Box>
      <Box
        sx={{
          mt: 2,
        }}
      >
        <Typography
          textAlign={'center'}
          sx={{ fontSize: 11, lineHeight: 16 / 11, color: '#000' }}
        >
          ※人数や時間は大まかな目安です。順番が前後することがあります。
        </Typography>
      </Box>
      <Box sx={{ m: 'auto', mt: 4, width: '280px' }}>
        <NavigationButton
          onClick={cancelButtonHandle}
          labelColor={'#000000'}
          backgroundColor={'#89B8DC'}
        >
          キャンセルする
        </NavigationButton>
      </Box>
      <CancelModal
        open={cancelModalOpen}
        onCancel={cancelHandle}
        onClose={() => {
          setCancelModalOpen(false);
          dispatch(clearAction());
        }}
      ></CancelModal>
      <ErrorModal
        open={errorModalOpen}
        onClose={() => {
          setErrorModalOpen(false);
        }}
        message1={errorModalText}
      ></ErrorModal>
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: 9999,
        }}
        open={loadingOpen}
      >
        <Loading />
      </Backdrop>
    </Box>
  );
};
export default WaitAdjustment;
