import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

type TopWaitingNumberForAdjustmentProps = {
  waitingCount: number | string;
};
const TopWaitingNumberForAdjustment = (
  props: TopWaitingNumberForAdjustmentProps,
) => {
  return (
    <Box
      sx={{
        borderRadius: '20px',
        pt: '20px',
        pl: '40px',
        pr: '40px',
        pb: '20px',
        bgcolor: '#ffffff',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <Typography
        sx={{
          fontSize: 16,
          fontWeight: 700,
          color: '#000000',
          lineHeight: 24 / 16,
          pt: '5px',
        }}
      >
        受け取り・
        <br />
        調整の待ち人数
      </Typography>
      <Box>
        <Typography
          display={'inline-block'}
          fontSize={50}
          fontWeight={500}
          lineHeight={40 / 50}
          color={'#000000'}
          pt="13px"
        >
          {props.waitingCount}
        </Typography>
        <Typography
          display={'inline-block'}
          fontSize={18}
          fontWeight={500}
          lineHeight={26 / 18}
          color={'#000000'}
          ml={'10px'}
        >
          人
        </Typography>
      </Box>
    </Box>
  );
};
export default TopWaitingNumberForAdjustment;
