import { Box, Typography } from '@mui/material';
import Modal, { ModalProps } from 'components/Modal';
import NavigationButton from 'components/NavigationButton';
import React from 'react';

export type CancelModalProps = Omit<ModalProps, 'children'> & {
  onCancel: () => any;
};

export default function CancelModal(props: CancelModalProps) {
  const closeButtonHandle = () => {
    props.onClose && props.onClose({}, 'backdropClick');
  };
  return (
    <Modal {...props}>
      <Box sx={{ position: 'relative', pt: 10, pb: 5 }}>
        <Box>
          <Typography
            textAlign={'center'}
            sx={{
              fontSize: 16,
              fontWeight: 500,
              lineHeight: 26 / 16,
            }}
          >
            キャンセルしてよろしいですか？
            <br />
            キャンセルすると再度最初から
            <br />
            並び直しとなります。
          </Typography>
        </Box>
        <Box sx={{ width: '280px', m: 'auto', mt: 6 }}>
          <NavigationButton
            labelColor={'#000000'}
            backgroundColor={'#53B385'}
            onClick={props.onCancel}
          >
            キャンセルする
          </NavigationButton>
        </Box>
        <Box sx={{ width: '280px', m: 'auto', mt: 2 }}>
          <NavigationButton
            labelColor={'#000000'}
            backgroundColor={'#ffffff'}
            onClick={closeButtonHandle}
          >
            閉じる
          </NavigationButton>
        </Box>
      </Box>
    </Modal>
  );
}
