import { Button } from '@mui/material';
import React, { ReactNode } from 'react';

type RadioBoxProps = {
  value: boolean;
  onChange?: (e: boolean) => void;
  children?: ReactNode;
  disabled?: boolean;
};
const RadioBox = function (props: RadioBoxProps) {
  function onClick() {
    if (!props.value && props.onChange) {
      props.onChange(true);
    }
  }
  return (
    <Button
      disabled={props.disabled || false}
      sx={{
        backgroundColor: props.value ? '#1C573B' : '#ffffff',
        color: props.value ? '#ffffff' : '#000000',
        borderRadius: 100,
        fontSize: 12,
        lineHeight: 20 / 12,
        height: 60,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
        '&:hover': {
          backgroundColor: props.value ? '#1C573B' : '#ffffff',
          color: props.value ? '#ffffff' : '#000000',
        },
        '&.Mui-disabled': {
          backgroundColor: '#A0A0A0',
          color: '#ffffff',
        },
      }}
      onClick={onClick}
    >
      {props.children}
    </Button>
  );
};
export default RadioBox;
